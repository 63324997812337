import React from 'react';
import { Box, Flex } from '@nelson-ui/react';
import { SHOW_STATUS } from '@common/constants';
import { Text } from '@components/text';

export const StatusBar: React.FC<{ children?: React.ReactNode }> = () => {
  if (SHOW_STATUS === false) return null;

  return (
    <Flex
      width="100%"
      py={'20px'}
      px={'29px'}
      alignItems={'center'}
      flexDirection={'column'}
      border="1px solid $onSurface-border-subdued"
      gap="15px"
      className="bg-dark-background-background-subdued"
    >
      <Box width="100%">
        <Text variant="Body02" mx="auto" maxWidth="500px" textAlign="center">
          Due to issues with the Dots API, some functionality may be degraded.
        </Text>
      </Box>
      <Box width="100%">
        <Text variant="Body02" mx="auto" maxWidth="500px" textAlign="center">
          If your name doesn&apos;t show up on the home page, try searching for it.
        </Text>
      </Box>
    </Flex>
  );
};
