// src/clarigen.ts
var contracts = {
  bnsV1: {
    functions: {
      getExpAtIndex: {
        name: "get-exp-at-index",
        access: "private",
        args: [
          { name: "buckets", type: { list: { type: "uint128", length: 16 } } },
          { name: "index", type: "uint128" }
        ],
        outputs: { type: "uint128" }
      },
      isDigit: {
        name: "is-digit",
        access: "private",
        args: [{ name: "char", type: { buffer: { length: 1 } } }],
        outputs: { type: "bool" }
      },
      isLowercaseAlpha: {
        name: "is-lowercase-alpha",
        access: "private",
        args: [{ name: "char", type: { buffer: { length: 1 } } }],
        outputs: { type: "bool" }
      },
      isNamespaceAvailable: {
        name: "is-namespace-available",
        access: "private",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: "bool" }
      },
      isNonalpha: {
        name: "is-nonalpha",
        access: "private",
        args: [{ name: "char", type: { buffer: { length: 1 } } }],
        outputs: { type: "bool" }
      },
      isSpecialChar: {
        name: "is-special-char",
        access: "private",
        args: [{ name: "char", type: { buffer: { length: 1 } } }],
        outputs: { type: "bool" }
      },
      isVowel: {
        name: "is-vowel",
        access: "private",
        args: [{ name: "char", type: { buffer: { length: 1 } } }],
        outputs: { type: "bool" }
      },
      max: {
        name: "max",
        access: "private",
        args: [
          { name: "a", type: "uint128" },
          { name: "b", type: "uint128" }
        ],
        outputs: { type: "uint128" }
      },
      min: {
        name: "min",
        access: "private",
        args: [
          { name: "a", type: "uint128" },
          { name: "b", type: "uint128" }
        ],
        outputs: { type: "uint128" }
      },
      mintOrTransferName: {
        name: "mint-or-transfer-name?",
        access: "private",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "beneficiary", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      nameLeaseStartedAt: {
        name: "name-lease-started-at?",
        access: "private",
        args: [
          { name: "namespace-launched-at", type: { optional: "uint128" } },
          { name: "namespace-revealed-at", type: "uint128" },
          {
            name: "name-props",
            type: {
              tuple: [
                { name: "imported-at", type: { optional: "uint128" } },
                { name: "registered-at", type: { optional: "uint128" } },
                { name: "revoked-at", type: { optional: "uint128" } },
                { name: "zonefile-hash", type: { buffer: { length: 20 } } }
              ]
            }
          }
        ],
        outputs: { type: { response: { ok: "uint128", error: "int128" } } }
      },
      updateNameOwnership: {
        name: "update-name-ownership?",
        access: "private",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "from", type: "principal" },
          { name: "to", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      updateZonefileAndProps: {
        name: "update-zonefile-and-props",
        access: "private",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "registered-at", type: { optional: "uint128" } },
          { name: "imported-at", type: { optional: "uint128" } },
          { name: "revoked-at", type: { optional: "uint128" } },
          { name: "zonefile-hash", type: { buffer: { length: 20 } } },
          { name: "op", type: { "string-ascii": { length: 16 } } }
        ],
        outputs: { type: "bool" }
      },
      nameImport: {
        name: "name-import",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "beneficiary", type: "principal" },
          { name: "zonefile-hash", type: { buffer: { length: 20 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      namePreorder: {
        name: "name-preorder",
        access: "public",
        args: [
          { name: "hashed-salted-fqn", type: { buffer: { length: 20 } } },
          { name: "stx-to-burn", type: "uint128" }
        ],
        outputs: { type: { response: { ok: "uint128", error: "int128" } } }
      },
      nameRegister: {
        name: "name-register",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "salt", type: { buffer: { length: 20 } } },
          { name: "zonefile-hash", type: { buffer: { length: 20 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      nameRenewal: {
        name: "name-renewal",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "stx-to-burn", type: "uint128" },
          { name: "new-owner", type: { optional: "principal" } },
          { name: "zonefile-hash", type: { optional: { buffer: { length: 20 } } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      nameRevoke: {
        name: "name-revoke",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      nameTransfer: {
        name: "name-transfer",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "new-owner", type: "principal" },
          { name: "zonefile-hash", type: { optional: { buffer: { length: 20 } } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      nameUpdate: {
        name: "name-update",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "zonefile-hash", type: { buffer: { length: 20 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      namespacePreorder: {
        name: "namespace-preorder",
        access: "public",
        args: [
          { name: "hashed-salted-namespace", type: { buffer: { length: 20 } } },
          { name: "stx-to-burn", type: "uint128" }
        ],
        outputs: { type: { response: { ok: "uint128", error: "int128" } } }
      },
      namespaceReady: {
        name: "namespace-ready",
        access: "public",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      namespaceReveal: {
        name: "namespace-reveal",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "namespace-salt", type: { buffer: { length: 20 } } },
          { name: "p-func-base", type: "uint128" },
          { name: "p-func-coeff", type: "uint128" },
          { name: "p-func-b1", type: "uint128" },
          { name: "p-func-b2", type: "uint128" },
          { name: "p-func-b3", type: "uint128" },
          { name: "p-func-b4", type: "uint128" },
          { name: "p-func-b5", type: "uint128" },
          { name: "p-func-b6", type: "uint128" },
          { name: "p-func-b7", type: "uint128" },
          { name: "p-func-b8", type: "uint128" },
          { name: "p-func-b9", type: "uint128" },
          { name: "p-func-b10", type: "uint128" },
          { name: "p-func-b11", type: "uint128" },
          { name: "p-func-b12", type: "uint128" },
          { name: "p-func-b13", type: "uint128" },
          { name: "p-func-b14", type: "uint128" },
          { name: "p-func-b15", type: "uint128" },
          { name: "p-func-b16", type: "uint128" },
          { name: "p-func-non-alpha-discount", type: "uint128" },
          { name: "p-func-no-vowel-discount", type: "uint128" },
          { name: "lifetime", type: "uint128" },
          { name: "namespace-import", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      namespaceRevokeFunctionPriceEdition: {
        name: "namespace-revoke-function-price-edition",
        access: "public",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      namespaceUpdateFunctionPrice: {
        name: "namespace-update-function-price",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "p-func-base", type: "uint128" },
          { name: "p-func-coeff", type: "uint128" },
          { name: "p-func-b1", type: "uint128" },
          { name: "p-func-b2", type: "uint128" },
          { name: "p-func-b3", type: "uint128" },
          { name: "p-func-b4", type: "uint128" },
          { name: "p-func-b5", type: "uint128" },
          { name: "p-func-b6", type: "uint128" },
          { name: "p-func-b7", type: "uint128" },
          { name: "p-func-b8", type: "uint128" },
          { name: "p-func-b9", type: "uint128" },
          { name: "p-func-b10", type: "uint128" },
          { name: "p-func-b11", type: "uint128" },
          { name: "p-func-b12", type: "uint128" },
          { name: "p-func-b13", type: "uint128" },
          { name: "p-func-b14", type: "uint128" },
          { name: "p-func-b15", type: "uint128" },
          { name: "p-func-b16", type: "uint128" },
          { name: "p-func-non-alpha-discount", type: "uint128" },
          { name: "p-func-no-vowel-discount", type: "uint128" }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      canNameBeRegistered: {
        name: "can-name-be-registered",
        access: "read_only",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      canNamespaceBeRegistered: {
        name: "can-namespace-be-registered",
        access: "read_only",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: { response: { ok: "bool", error: "none" } } }
      },
      canReceiveName: {
        name: "can-receive-name",
        access: "read_only",
        args: [{ name: "owner", type: "principal" }],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      checkNameOpsPreconditions: {
        name: "check-name-ops-preconditions",
        access: "read_only",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } }
        ],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  {
                    name: "name-props",
                    type: {
                      tuple: [
                        { name: "imported-at", type: { optional: "uint128" } },
                        { name: "registered-at", type: { optional: "uint128" } },
                        { name: "revoked-at", type: { optional: "uint128" } },
                        { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                      ]
                    }
                  },
                  {
                    name: "namespace-props",
                    type: {
                      tuple: [
                        { name: "can-update-price-function", type: "bool" },
                        { name: "launched-at", type: { optional: "uint128" } },
                        { name: "lifetime", type: "uint128" },
                        { name: "namespace-import", type: "principal" },
                        {
                          name: "price-function",
                          type: {
                            tuple: [
                              { name: "base", type: "uint128" },
                              { name: "buckets", type: { list: { type: "uint128", length: 16 } } },
                              { name: "coeff", type: "uint128" },
                              { name: "no-vowel-discount", type: "uint128" },
                              { name: "nonalpha-discount", type: "uint128" }
                            ]
                          }
                        },
                        { name: "revealed-at", type: "uint128" }
                      ]
                    }
                  },
                  { name: "owner", type: "principal" }
                ]
              },
              error: "int128"
            }
          }
        }
      },
      computeNamePrice: {
        name: "compute-name-price",
        access: "read_only",
        args: [
          { name: "name", type: { buffer: { length: 48 } } },
          {
            name: "price-function",
            type: {
              tuple: [
                { name: "base", type: "uint128" },
                { name: "buckets", type: { list: { type: "uint128", length: 16 } } },
                { name: "coeff", type: "uint128" },
                { name: "no-vowel-discount", type: "uint128" },
                { name: "nonalpha-discount", type: "uint128" }
              ]
            }
          }
        ],
        outputs: { type: "uint128" }
      },
      getNamePrice: {
        name: "get-name-price",
        access: "read_only",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } }
        ],
        outputs: { type: { response: { ok: "uint128", error: "int128" } } }
      },
      getNamespacePrice: {
        name: "get-namespace-price",
        access: "read_only",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: { response: { ok: "uint128", error: "int128" } } }
      },
      getNamespaceProperties: {
        name: "get-namespace-properties",
        access: "read_only",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  {
                    name: "properties",
                    type: {
                      tuple: [
                        { name: "can-update-price-function", type: "bool" },
                        { name: "launched-at", type: { optional: "uint128" } },
                        { name: "lifetime", type: "uint128" },
                        { name: "namespace-import", type: "principal" },
                        {
                          name: "price-function",
                          type: {
                            tuple: [
                              { name: "base", type: "uint128" },
                              { name: "buckets", type: { list: { type: "uint128", length: 16 } } },
                              { name: "coeff", type: "uint128" },
                              { name: "no-vowel-discount", type: "uint128" },
                              { name: "nonalpha-discount", type: "uint128" }
                            ]
                          }
                        },
                        { name: "revealed-at", type: "uint128" }
                      ]
                    }
                  }
                ]
              },
              error: "int128"
            }
          }
        }
      },
      hasInvalidChars: {
        name: "has-invalid-chars",
        access: "read_only",
        args: [{ name: "name", type: { buffer: { length: 48 } } }],
        outputs: { type: "bool" }
      },
      hasNonalphaChars: {
        name: "has-nonalpha-chars",
        access: "read_only",
        args: [{ name: "name", type: { buffer: { length: 48 } } }],
        outputs: { type: "bool" }
      },
      hasVowelsChars: {
        name: "has-vowels-chars",
        access: "read_only",
        args: [{ name: "name", type: { buffer: { length: 48 } } }],
        outputs: { type: "bool" }
      },
      isCharValid: {
        name: "is-char-valid",
        access: "read_only",
        args: [{ name: "char", type: { buffer: { length: 1 } } }],
        outputs: { type: "bool" }
      },
      isNameInGracePeriod: {
        name: "is-name-in-grace-period",
        access: "read_only",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      isNameLeaseExpired: {
        name: "is-name-lease-expired",
        access: "read_only",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      nameResolve: {
        name: "name-resolve",
        access: "read_only",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } }
        ],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "lease-ending-at", type: { optional: "uint128" } },
                  { name: "lease-started-at", type: "uint128" },
                  { name: "owner", type: "principal" },
                  { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                ]
              },
              error: "int128"
            }
          }
        }
      },
      resolvePrincipal: {
        name: "resolve-principal",
        access: "read_only",
        args: [{ name: "owner", type: "principal" }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } }
                ]
              },
              error: {
                tuple: [
                  { name: "code", type: "int128" },
                  {
                    name: "name",
                    type: {
                      optional: {
                        tuple: [
                          { name: "name", type: { buffer: { length: 48 } } },
                          { name: "namespace", type: { buffer: { length: 20 } } }
                        ]
                      }
                    }
                  }
                ]
              }
            }
          }
        }
      }
    },
    maps: {
      namePreorders: {
        name: "name-preorders",
        key: {
          tuple: [
            { name: "buyer", type: "principal" },
            { name: "hashed-salted-fqn", type: { buffer: { length: 20 } } }
          ]
        },
        value: {
          tuple: [
            { name: "claimed", type: "bool" },
            { name: "created-at", type: "uint128" },
            { name: "stx-burned", type: "uint128" }
          ]
        }
      },
      nameProperties: {
        name: "name-properties",
        key: {
          tuple: [
            { name: "name", type: { buffer: { length: 48 } } },
            { name: "namespace", type: { buffer: { length: 20 } } }
          ]
        },
        value: {
          tuple: [
            { name: "imported-at", type: { optional: "uint128" } },
            { name: "registered-at", type: { optional: "uint128" } },
            { name: "revoked-at", type: { optional: "uint128" } },
            { name: "zonefile-hash", type: { buffer: { length: 20 } } }
          ]
        }
      },
      namespacePreorders: {
        name: "namespace-preorders",
        key: {
          tuple: [
            { name: "buyer", type: "principal" },
            { name: "hashed-salted-namespace", type: { buffer: { length: 20 } } }
          ]
        },
        value: {
          tuple: [
            { name: "claimed", type: "bool" },
            { name: "created-at", type: "uint128" },
            { name: "stx-burned", type: "uint128" }
          ]
        }
      },
      namespaces: {
        name: "namespaces",
        key: { buffer: { length: 20 } },
        value: {
          tuple: [
            { name: "can-update-price-function", type: "bool" },
            { name: "launched-at", type: { optional: "uint128" } },
            { name: "lifetime", type: "uint128" },
            { name: "namespace-import", type: "principal" },
            {
              name: "price-function",
              type: {
                tuple: [
                  { name: "base", type: "uint128" },
                  { name: "buckets", type: { list: { type: "uint128", length: 16 } } },
                  { name: "coeff", type: "uint128" },
                  { name: "no-vowel-discount", type: "uint128" },
                  { name: "nonalpha-discount", type: "uint128" }
                ]
              }
            },
            { name: "revealed-at", type: "uint128" }
          ]
        }
      },
      ownerName: {
        name: "owner-name",
        key: "principal",
        value: {
          tuple: [
            { name: "name", type: { buffer: { length: 48 } } },
            { name: "namespace", type: { buffer: { length: 20 } } }
          ]
        }
      }
    },
    variables: {
      ERR_INSUFFICIENT_FUNDS: {
        name: "ERR_INSUFFICIENT_FUNDS",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_ALREADY_EXISTS: {
        name: "ERR_NAMESPACE_ALREADY_EXISTS",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_ALREADY_LAUNCHED: {
        name: "ERR_NAMESPACE_ALREADY_LAUNCHED",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_BLANK: {
        name: "ERR_NAMESPACE_BLANK",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_CHARSET_INVALID: {
        name: "ERR_NAMESPACE_CHARSET_INVALID",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_HASH_MALFORMED: {
        name: "ERR_NAMESPACE_HASH_MALFORMED",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_NOT_FOUND: {
        name: "ERR_NAMESPACE_NOT_FOUND",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_NOT_LAUNCHED: {
        name: "ERR_NAMESPACE_NOT_LAUNCHED",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_OPERATION_UNAUTHORIZED: {
        name: "ERR_NAMESPACE_OPERATION_UNAUTHORIZED",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_PREORDER_ALREADY_EXISTS: {
        name: "ERR_NAMESPACE_PREORDER_ALREADY_EXISTS",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_PREORDER_CLAIMABILITY_EXPIRED: {
        name: "ERR_NAMESPACE_PREORDER_CLAIMABILITY_EXPIRED",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_PREORDER_EXPIRED: {
        name: "ERR_NAMESPACE_PREORDER_EXPIRED",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_PREORDER_LAUNCHABILITY_EXPIRED: {
        name: "ERR_NAMESPACE_PREORDER_LAUNCHABILITY_EXPIRED",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_PREORDER_NOT_FOUND: {
        name: "ERR_NAMESPACE_PREORDER_NOT_FOUND",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_PRICE_FUNCTION_INVALID: {
        name: "ERR_NAMESPACE_PRICE_FUNCTION_INVALID",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_STX_BURNT_INSUFFICIENT: {
        name: "ERR_NAMESPACE_STX_BURNT_INSUFFICIENT",
        type: "int128",
        access: "constant"
      },
      ERR_NAMESPACE_UNAVAILABLE: {
        name: "ERR_NAMESPACE_UNAVAILABLE",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_ALREADY_CLAIMED: {
        name: "ERR_NAME_ALREADY_CLAIMED",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_BLANK: {
        name: "ERR_NAME_BLANK",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_CHARSET_INVALID: {
        name: "ERR_NAME_CHARSET_INVALID",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_CLAIMABILITY_EXPIRED: {
        name: "ERR_NAME_CLAIMABILITY_EXPIRED",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_COULD_NOT_BE_MINTED: {
        name: "ERR_NAME_COULD_NOT_BE_MINTED",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_COULD_NOT_BE_TRANSFERED: {
        name: "ERR_NAME_COULD_NOT_BE_TRANSFERED",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_EXPIRED: {
        name: "ERR_NAME_EXPIRED",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_GRACE_PERIOD: {
        name: "ERR_NAME_GRACE_PERIOD",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_HASH_MALFORMED: {
        name: "ERR_NAME_HASH_MALFORMED",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_NOT_FOUND: {
        name: "ERR_NAME_NOT_FOUND",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_NOT_RESOLVABLE: {
        name: "ERR_NAME_NOT_RESOLVABLE",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_OPERATION_UNAUTHORIZED: {
        name: "ERR_NAME_OPERATION_UNAUTHORIZED",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_PREORDERED_BEFORE_NAMESPACE_LAUNCH: {
        name: "ERR_NAME_PREORDERED_BEFORE_NAMESPACE_LAUNCH",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_PREORDER_ALREADY_EXISTS: {
        name: "ERR_NAME_PREORDER_ALREADY_EXISTS",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_PREORDER_EXPIRED: {
        name: "ERR_NAME_PREORDER_EXPIRED",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_PREORDER_FUNDS_INSUFFICIENT: {
        name: "ERR_NAME_PREORDER_FUNDS_INSUFFICIENT",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_PREORDER_NOT_FOUND: {
        name: "ERR_NAME_PREORDER_NOT_FOUND",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_REVOKED: {
        name: "ERR_NAME_REVOKED",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_STX_BURNT_INSUFFICIENT: {
        name: "ERR_NAME_STX_BURNT_INSUFFICIENT",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_TRANSFER_FAILED: {
        name: "ERR_NAME_TRANSFER_FAILED",
        type: "int128",
        access: "constant"
      },
      ERR_NAME_UNAVAILABLE: {
        name: "ERR_NAME_UNAVAILABLE",
        type: "int128",
        access: "constant"
      },
      ERR_PANIC: {
        name: "ERR_PANIC",
        type: "int128",
        access: "constant"
      },
      ERR_PRINCIPAL_ALREADY_ASSOCIATED: {
        name: "ERR_PRINCIPAL_ALREADY_ASSOCIATED",
        type: "int128",
        access: "constant"
      },
      NAMESPACE_LAUNCHABILITY_TTL: {
        name: "NAMESPACE_LAUNCHABILITY_TTL",
        type: "uint128",
        access: "constant"
      },
      NAMESPACE_PREORDER_CLAIMABILITY_TTL: {
        name: "NAMESPACE_PREORDER_CLAIMABILITY_TTL",
        type: "uint128",
        access: "constant"
      },
      NAMESPACE_PRICE_TIERS: {
        name: "NAMESPACE_PRICE_TIERS",
        type: {
          list: {
            type: "uint128",
            length: 20
          }
        },
        access: "constant"
      },
      NAME_GRACE_PERIOD_DURATION: {
        name: "NAME_GRACE_PERIOD_DURATION",
        type: "uint128",
        access: "constant"
      },
      NAME_PREORDER_CLAIMABILITY_TTL: {
        name: "NAME_PREORDER_CLAIMABILITY_TTL",
        type: "uint128",
        access: "constant"
      },
      attachmentIndex: {
        name: "attachment-index",
        type: "uint128",
        access: "variable"
      }
    },
    constants: {
      ERR_INSUFFICIENT_FUNDS: 4001n,
      ERR_NAMESPACE_ALREADY_EXISTS: 1006n,
      ERR_NAMESPACE_ALREADY_LAUNCHED: 1014n,
      ERR_NAMESPACE_BLANK: 1013n,
      ERR_NAMESPACE_CHARSET_INVALID: 1016n,
      ERR_NAMESPACE_HASH_MALFORMED: 1015n,
      ERR_NAMESPACE_NOT_FOUND: 1005n,
      ERR_NAMESPACE_NOT_LAUNCHED: 1007n,
      ERR_NAMESPACE_OPERATION_UNAUTHORIZED: 1011n,
      ERR_NAMESPACE_PREORDER_ALREADY_EXISTS: 1003n,
      ERR_NAMESPACE_PREORDER_CLAIMABILITY_EXPIRED: 1009n,
      ERR_NAMESPACE_PREORDER_EXPIRED: 1002n,
      ERR_NAMESPACE_PREORDER_LAUNCHABILITY_EXPIRED: 1010n,
      ERR_NAMESPACE_PREORDER_NOT_FOUND: 1001n,
      ERR_NAMESPACE_PRICE_FUNCTION_INVALID: 1008n,
      ERR_NAMESPACE_STX_BURNT_INSUFFICIENT: 1012n,
      ERR_NAMESPACE_UNAVAILABLE: 1004n,
      ERR_NAME_ALREADY_CLAIMED: 2011n,
      ERR_NAME_BLANK: 2010n,
      ERR_NAME_CHARSET_INVALID: 2022n,
      ERR_NAME_CLAIMABILITY_EXPIRED: 2012n,
      ERR_NAME_COULD_NOT_BE_MINTED: 2020n,
      ERR_NAME_COULD_NOT_BE_TRANSFERED: 2021n,
      ERR_NAME_EXPIRED: 2008n,
      ERR_NAME_GRACE_PERIOD: 2009n,
      ERR_NAME_HASH_MALFORMED: 2017n,
      ERR_NAME_NOT_FOUND: 2013n,
      ERR_NAME_NOT_RESOLVABLE: 2019n,
      ERR_NAME_OPERATION_UNAUTHORIZED: 2006n,
      ERR_NAME_PREORDERED_BEFORE_NAMESPACE_LAUNCH: 2018n,
      ERR_NAME_PREORDER_ALREADY_EXISTS: 2016n,
      ERR_NAME_PREORDER_EXPIRED: 2002n,
      ERR_NAME_PREORDER_FUNDS_INSUFFICIENT: 2003n,
      ERR_NAME_PREORDER_NOT_FOUND: 2001n,
      ERR_NAME_REVOKED: 2014n,
      ERR_NAME_STX_BURNT_INSUFFICIENT: 2007n,
      ERR_NAME_TRANSFER_FAILED: 2015n,
      ERR_NAME_UNAVAILABLE: 2004n,
      ERR_PANIC: 0n,
      ERR_PRINCIPAL_ALREADY_ASSOCIATED: 3001n,
      NAMESPACE_LAUNCHABILITY_TTL: 52595n,
      NAMESPACE_PREORDER_CLAIMABILITY_TTL: 144n,
      NAMESPACE_PRICE_TIERS: [
        640000000000n,
        64000000000n,
        64000000000n,
        6400000000n,
        6400000000n,
        6400000000n,
        6400000000n,
        640000000n,
        640000000n,
        640000000n,
        640000000n,
        640000000n,
        640000000n,
        640000000n,
        640000000n,
        640000000n,
        640000000n,
        640000000n,
        640000000n,
        640000000n
      ],
      NAME_GRACE_PERIOD_DURATION: 5000n,
      NAME_PREORDER_CLAIMABILITY_TTL: 144n,
      attachmentIndex: 0n
    },
    non_fungible_tokens: [
      {
        name: "names",
        type: {
          tuple: [
            { name: "name", type: { buffer: { length: 48 } } },
            { name: "namespace", type: { buffer: { length: 20 } } }
          ]
        }
      }
    ],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "bns-v1"
  },
  bnsxExtensions: {
    functions: {
      isSelfOrExtension: {
        name: "is-self-or-extension",
        access: "private",
        args: [],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      setExtensionsIter: {
        name: "set-extensions-iter",
        access: "private",
        args: [
          {
            name: "item",
            type: {
              tuple: [
                { name: "enabled", type: "bool" },
                { name: "extension", type: "principal" }
              ]
            }
          }
        ],
        outputs: { type: "bool" }
      },
      setRolesIter: {
        name: "set-roles-iter",
        access: "private",
        args: [
          {
            name: "item",
            type: {
              tuple: [
                { name: "enabled", type: "bool" },
                { name: "extension", type: "principal" },
                { name: "role", type: { "string-ascii": { length: 10 } } }
              ]
            }
          }
        ],
        outputs: { type: "bool" }
      },
      construct: {
        name: "construct",
        access: "public",
        args: [{ name: "proposal", type: "trait_reference" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      execute: {
        name: "execute",
        access: "public",
        args: [
          { name: "proposal", type: "trait_reference" },
          { name: "sender", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      requestExtensionCallback: {
        name: "request-extension-callback",
        access: "public",
        args: [
          { name: "extension", type: "trait_reference" },
          { name: "memo", type: { buffer: { length: 34 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      setExtensionRoles: {
        name: "set-extension-roles",
        access: "public",
        args: [
          {
            name: "extension-list",
            type: {
              list: {
                type: {
                  tuple: [
                    { name: "enabled", type: "bool" },
                    { name: "extension", type: "principal" },
                    { name: "role", type: { "string-ascii": { length: 10 } } }
                  ]
                },
                length: 200
              }
            }
          }
        ],
        outputs: {
          type: { response: { ok: { list: { type: "bool", length: 200 } }, error: "uint128" } }
        }
      },
      setExtensions: {
        name: "set-extensions",
        access: "public",
        args: [
          {
            name: "extension-list",
            type: {
              list: {
                type: {
                  tuple: [
                    { name: "enabled", type: "bool" },
                    { name: "extension", type: "principal" }
                  ]
                },
                length: 200
              }
            }
          }
        ],
        outputs: {
          type: { response: { ok: { list: { type: "bool", length: 200 } }, error: "uint128" } }
        }
      },
      executedAt: {
        name: "executed-at",
        access: "read_only",
        args: [{ name: "proposal", type: "trait_reference" }],
        outputs: { type: { optional: "uint128" } }
      },
      hasRole: {
        name: "has-role",
        access: "read_only",
        args: [
          { name: "extension", type: "principal" },
          { name: "role", type: { "string-ascii": { length: 10 } } }
        ],
        outputs: { type: "bool" }
      },
      hasRoleOrExtension: {
        name: "has-role-or-extension",
        access: "read_only",
        args: [
          { name: "extension", type: "principal" },
          { name: "role", type: { "string-ascii": { length: 10 } } }
        ],
        outputs: { type: "bool" }
      },
      isExtension: {
        name: "is-extension",
        access: "read_only",
        args: [{ name: "extension", type: "principal" }],
        outputs: { type: "bool" }
      }
    },
    maps: {
      executedProposals: {
        name: "executed-proposals",
        key: "principal",
        value: "uint128"
      },
      extensionRoles: {
        name: "extension-roles",
        key: {
          tuple: [
            { name: "extension", type: "principal" },
            { name: "role", type: { "string-ascii": { length: 10 } } }
          ]
        },
        value: "bool"
      },
      extensions: { name: "extensions", key: "principal", value: "bool" }
    },
    variables: {
      errAlreadyExecuted: {
        name: "err-already-executed",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      errInvalidExtension: {
        name: "err-invalid-extension",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      errUnauthorised: {
        name: "err-unauthorised",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      executive: {
        name: "executive",
        type: "principal",
        access: "variable"
      }
    },
    constants: {
      errAlreadyExecuted: {
        isOk: false,
        value: 1001n
      },
      errInvalidExtension: {
        isOk: false,
        value: 1002n
      },
      errUnauthorised: {
        isOk: false,
        value: 1000n
      },
      executive: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM"
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "bnsx-extensions"
  },
  bnsxRegistry: {
    functions: {
      addNode: {
        name: "add-node",
        access: "private",
        args: [
          { name: "account", type: "principal" },
          { name: "id", type: "uint128" }
        ],
        outputs: { type: "bool" }
      },
      burnName: {
        name: "burn-name",
        access: "private",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      incrementId: {
        name: "increment-id",
        access: "private",
        args: [],
        outputs: { type: "uint128" }
      },
      mergeNameProps: {
        name: "merge-name-props",
        access: "private",
        args: [
          {
            name: "name",
            type: {
              tuple: [
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } }
              ]
            }
          },
          { name: "id", type: "uint128" }
        ],
        outputs: {
          type: {
            optional: {
              tuple: [
                { name: "id", type: "uint128" },
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } },
                { name: "owner", type: "principal" }
              ]
            }
          }
        }
      },
      printPrimaryUpdate: {
        name: "print-primary-update",
        access: "private",
        args: [
          { name: "account", type: "principal" },
          { name: "id", type: { optional: "uint128" } }
        ],
        outputs: { type: "bool" }
      },
      removeNode: {
        name: "remove-node",
        access: "private",
        args: [
          { name: "account", type: "principal" },
          { name: "id", type: "uint128" }
        ],
        outputs: { type: "bool" }
      },
      setFirst: {
        name: "set-first",
        access: "private",
        args: [
          { name: "account", type: "principal" },
          { name: "node", type: "uint128" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      transferOwnership: {
        name: "transfer-ownership",
        access: "private",
        args: [
          { name: "id", type: "uint128" },
          { name: "sender", type: "principal" },
          { name: "recipient", type: "principal" }
        ],
        outputs: { type: "bool" }
      },
      burn: {
        name: "burn",
        access: "public",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      mngBurn: {
        name: "mng-burn",
        access: "public",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      mngSetNamespaceTokenUri: {
        name: "mng-set-namespace-token-uri",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "uri", type: { "string-ascii": { length: 256 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      mngSetTokenUri: {
        name: "mng-set-token-uri",
        access: "public",
        args: [{ name: "uri", type: { "string-ascii": { length: 256 } } }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      mngTransfer: {
        name: "mng-transfer",
        access: "public",
        args: [
          { name: "id", type: "uint128" },
          { name: "recipient", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      register: {
        name: "register",
        access: "public",
        args: [
          {
            name: "name",
            type: {
              tuple: [
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } }
              ]
            }
          },
          { name: "owner", type: "principal" }
        ],
        outputs: { type: { response: { ok: "uint128", error: "uint128" } } }
      },
      removeDaoNamespaceManager: {
        name: "remove-dao-namespace-manager",
        access: "public",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      setNamespaceManager: {
        name: "set-namespace-manager",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "manager", type: "principal" },
          { name: "enabled", type: "bool" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      setNamespaceTransfersAllowed: {
        name: "set-namespace-transfers-allowed",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "enabled", type: "bool" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      setPrimaryName: {
        name: "set-primary-name",
        access: "public",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      transfer: {
        name: "transfer",
        access: "public",
        args: [
          { name: "id", type: "uint128" },
          { name: "sender", type: "principal" },
          { name: "recipient", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      areTransfersAllowed: {
        name: "are-transfers-allowed",
        access: "read_only",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: "bool" }
      },
      canDaoManageNs: {
        name: "can-dao-manage-ns",
        access: "read_only",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: "bool" }
      },
      getBalance: {
        name: "get-balance",
        access: "read_only",
        args: [{ name: "account", type: "principal" }],
        outputs: { type: "uint128" }
      },
      getBalanceOf: {
        name: "get-balance-of",
        access: "read_only",
        args: [{ name: "account", type: "principal" }],
        outputs: { type: { response: { ok: "uint128", error: "none" } } }
      },
      getIdForName: {
        name: "get-id-for-name",
        access: "read_only",
        args: [
          {
            name: "name",
            type: {
              tuple: [
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } }
              ]
            }
          }
        ],
        outputs: { type: { optional: "uint128" } }
      },
      getLastTokenId: {
        name: "get-last-token-id",
        access: "read_only",
        args: [],
        outputs: { type: { response: { ok: "uint128", error: "none" } } }
      },
      getNameOwner: {
        name: "get-name-owner",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { optional: "principal" } }
      },
      getNameProperties: {
        name: "get-name-properties",
        access: "read_only",
        args: [
          {
            name: "name",
            type: {
              tuple: [
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } }
              ]
            }
          }
        ],
        outputs: {
          type: {
            optional: {
              tuple: [
                { name: "id", type: "uint128" },
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } },
                { name: "owner", type: "principal" }
              ]
            }
          }
        }
      },
      getNamePropertiesById: {
        name: "get-name-properties-by-id",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: {
          type: {
            optional: {
              tuple: [
                { name: "id", type: "uint128" },
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } },
                { name: "owner", type: "principal" }
              ]
            }
          }
        }
      },
      getNamespaceForId: {
        name: "get-namespace-for-id",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { response: { ok: { buffer: { length: 20 } }, error: "uint128" } } }
      },
      getNextNodeId: {
        name: "get-next-node-id",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { optional: "uint128" } }
      },
      getOwner: {
        name: "get-owner",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { response: { ok: { optional: "principal" }, error: "none" } } }
      },
      getPrimaryName: {
        name: "get-primary-name",
        access: "read_only",
        args: [{ name: "account", type: "principal" }],
        outputs: {
          type: {
            optional: {
              tuple: [
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } }
              ]
            }
          }
        }
      },
      getPrimaryNameProperties: {
        name: "get-primary-name-properties",
        access: "read_only",
        args: [{ name: "account", type: "principal" }],
        outputs: {
          type: {
            optional: {
              tuple: [
                { name: "id", type: "uint128" },
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } },
                { name: "owner", type: "principal" }
              ]
            }
          }
        }
      },
      getTokenUri: {
        name: "get-token-uri",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: {
          type: {
            response: { ok: { optional: { "string-ascii": { length: 256 } } }, error: "none" }
          }
        }
      },
      getTokenUriForNamespace: {
        name: "get-token-uri-for-namespace",
        access: "read_only",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: { optional: { "string-ascii": { length: 256 } } } }
      },
      isDaoOrExtension: {
        name: "is-dao-or-extension",
        access: "read_only",
        args: [],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      isNamespaceManager: {
        name: "is-namespace-manager",
        access: "read_only",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "manager", type: "principal" }
        ],
        outputs: { type: "bool" }
      },
      validateNamespaceAction: {
        name: "validate-namespace-action",
        access: "read_only",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      validateNamespaceActionById: {
        name: "validate-namespace-action-by-id",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      }
    },
    maps: {
      daoNamespaceManagerMap: {
        name: "dao-namespace-manager-map",
        key: { buffer: { length: 20 } },
        value: "bool"
      },
      idNameMap: {
        name: "id-name-map",
        key: "uint128",
        value: {
          tuple: [
            { name: "name", type: { buffer: { length: 48 } } },
            { name: "namespace", type: { buffer: { length: 20 } } }
          ]
        }
      },
      nameEncodingMap: {
        name: "name-encoding-map",
        key: "uint128",
        value: { buffer: { length: 1 } }
      },
      nameIdMap: {
        name: "name-id-map",
        key: {
          tuple: [
            { name: "name", type: { buffer: { length: 48 } } },
            { name: "namespace", type: { buffer: { length: 20 } } }
          ]
        },
        value: "uint128"
      },
      nameOwnerMap: { name: "name-owner-map", key: "uint128", value: "principal" },
      namespaceManagersMap: {
        name: "namespace-managers-map",
        key: {
          tuple: [
            { name: "manager", type: "principal" },
            { name: "namespace", type: { buffer: { length: 20 } } }
          ]
        },
        value: "bool"
      },
      namespaceTokenUriMap: {
        name: "namespace-token-uri-map",
        key: { buffer: { length: 20 } },
        value: { "string-ascii": { length: 256 } }
      },
      namespaceTransfersAllowed: {
        name: "namespace-transfers-allowed",
        key: { buffer: { length: 20 } },
        value: "bool"
      },
      ownerBalanceMap: {
        name: "owner-balance-map",
        key: "principal",
        value: "uint128"
      },
      ownerLastNameMap: {
        name: "owner-last-name-map",
        key: "principal",
        value: "uint128"
      },
      ownerNameNextMap: {
        name: "owner-name-next-map",
        key: "uint128",
        value: "uint128"
      },
      ownerNamePrevMap: {
        name: "owner-name-prev-map",
        key: "uint128",
        value: "uint128"
      },
      ownerPrimaryNameMap: {
        name: "owner-primary-name-map",
        key: "principal",
        value: "uint128"
      }
    },
    variables: {
      ERR_ALREADY_REGISTERED: {
        name: "ERR_ALREADY_REGISTERED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_CANNOT_SET_PRIMARY: {
        name: "ERR_CANNOT_SET_PRIMARY",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_EXPIRED: {
        name: "ERR_EXPIRED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_INVALID_ID: {
        name: "ERR_INVALID_ID",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NOT_OWNER: {
        name: "ERR_NOT_OWNER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_TRANSFER_BLOCKED: {
        name: "ERR_TRANSFER_BLOCKED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_UNAUTHORIZED: {
        name: "ERR_UNAUTHORIZED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ROLE: {
        name: "ROLE",
        type: {
          "string-ascii": {
            length: 8
          }
        },
        access: "constant"
      },
      lastIdVar: {
        name: "last-id-var",
        type: "uint128",
        access: "variable"
      },
      tokenUriVar: {
        name: "token-uri-var",
        type: {
          "string-ascii": {
            length: 256
          }
        },
        access: "variable"
      }
    },
    constants: {
      ERR_ALREADY_REGISTERED: {
        isOk: false,
        value: 4001n
      },
      ERR_CANNOT_SET_PRIMARY: {
        isOk: false,
        value: 4002n
      },
      ERR_EXPIRED: {
        isOk: false,
        value: 4004n
      },
      ERR_INVALID_ID: {
        isOk: false,
        value: 4003n
      },
      ERR_NOT_OWNER: {
        isOk: false,
        value: 4n
      },
      ERR_TRANSFER_BLOCKED: {
        isOk: false,
        value: 4005n
      },
      ERR_UNAUTHORIZED: {
        isOk: false,
        value: 4000n
      },
      ROLE: "registry",
      lastIdVar: 0n,
      tokenUriVar: ""
    },
    non_fungible_tokens: [{ name: "BNSx-Names", type: "uint128" }],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "bnsx-registry"
  },
  communityHandlesV2: {
    functions: {
      bulkNameRegisterIter: {
        name: "bulk-name-register-iter",
        access: "private",
        args: [
          {
            name: "entry",
            type: {
              tuple: [
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "owner", type: "principal" },
                { name: "zonefile-hash", type: { buffer: { length: 20 } } }
              ]
            }
          },
          { name: "prev", type: { response: { ok: "bool", error: "uint128" } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      isContractCallerNamespaceController: {
        name: "is-contract-caller-namespace-controller",
        access: "private",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      toBoolResponse: {
        name: "to-bool-response",
        access: "private",
        args: [{ name: "value", type: { response: { ok: "bool", error: "int128" } } }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      toUintResponse: {
        name: "to-uint-response",
        access: "private",
        args: [{ name: "value", type: { response: { ok: "uint128", error: "int128" } } }],
        outputs: { type: { response: { ok: "uint128", error: "uint128" } } }
      },
      bulkNameRegister: {
        name: "bulk-name-register",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          {
            name: "names",
            type: {
              list: {
                type: {
                  tuple: [
                    { name: "name", type: { buffer: { length: 48 } } },
                    { name: "owner", type: "principal" },
                    { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                  ]
                },
                length: 1e3
              }
            }
          }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      nameRegister: {
        name: "name-register",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "owner", type: "principal" },
          { name: "zonefile-hash", type: { buffer: { length: 20 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      nameRenewal: {
        name: "name-renewal",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "new-owner", type: { optional: "principal" } },
          { name: "zonefile-hash", type: { optional: { buffer: { length: 20 } } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      namespacePreorder: {
        name: "namespace-preorder",
        access: "public",
        args: [
          { name: "hashed-salted-namespace", type: { buffer: { length: 20 } } },
          { name: "stx-to-burn", type: "uint128" }
        ],
        outputs: { type: { response: { ok: "uint128", error: "int128" } } }
      },
      namespaceReveal: {
        name: "namespace-reveal",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "salt", type: { buffer: { length: 20 } } },
          { name: "lifetime", type: "uint128" },
          { name: "controller", type: { optional: "principal" } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      setNamespaceController: {
        name: "set-namespace-controller",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "new-controller", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      getNamespaceController: {
        name: "get-namespace-controller",
        access: "read_only",
        args: [{ name: "namespace", type: { buffer: { length: 20 } } }],
        outputs: { type: { optional: "principal" } }
      }
    },
    maps: {
      namespaceController: {
        name: "namespace-controller",
        key: { buffer: { length: 20 } },
        value: "principal"
      }
    },
    variables: {
      errNotAuthorized: {
        name: "err-not-authorized",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      internalPriceHigh: {
        name: "internal-price-high",
        type: "uint128",
        access: "constant"
      },
      nameSalt: {
        name: "name-salt",
        type: {
          buffer: {
            length: 1
          }
        },
        access: "constant"
      },
      ctxBulkRegistrationNamespace: {
        name: "ctx-bulk-registration-namespace",
        type: {
          buffer: {
            length: 20
          }
        },
        access: "variable"
      }
    },
    constants: {
      errNotAuthorized: {
        isOk: false,
        value: 403n
      },
      internalPriceHigh: 999999999999999999999999999999n,
      nameSalt: Uint8Array.from([0]),
      ctxBulkRegistrationNamespace: Uint8Array.from([0])
    },
    non_fungible_tokens: [],
    fungible_tokens: [{ name: "danger-zone-token" }],
    epoch: "Epoch21",
    clarity_version: "Clarity2",
    contractName: "community-handles-v2"
  },
  extensionTrait: {
    functions: {},
    maps: {},
    variables: {},
    constants: {},
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "extension-trait"
  },
  fakeFt: {
    functions: {
      mint: {
        name: "mint",
        access: "public",
        args: [
          { name: "amount", type: "uint128" },
          { name: "recipient", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      transfer: {
        name: "transfer",
        access: "public",
        args: [
          { name: "amount", type: "uint128" },
          { name: "sender", type: "principal" },
          { name: "recipient", type: "principal" },
          { name: "memo", type: { optional: { buffer: { length: 34 } } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      getBalance: {
        name: "get-balance",
        access: "read_only",
        args: [{ name: "owner", type: "principal" }],
        outputs: { type: { response: { ok: "uint128", error: "none" } } }
      },
      getDecimals: {
        name: "get-decimals",
        access: "read_only",
        args: [],
        outputs: { type: { response: { ok: "uint128", error: "none" } } }
      },
      getName: {
        name: "get-name",
        access: "read_only",
        args: [],
        outputs: { type: { response: { ok: { "string-ascii": { length: 10 } }, error: "none" } } }
      },
      getSymbol: {
        name: "get-symbol",
        access: "read_only",
        args: [],
        outputs: { type: { response: { ok: { "string-ascii": { length: 4 } }, error: "none" } } }
      },
      getTokenUri: {
        name: "get-token-uri",
        access: "read_only",
        args: [],
        outputs: { type: { response: { ok: { optional: "none" }, error: "none" } } }
      },
      getTotalSupply: {
        name: "get-total-supply",
        access: "read_only",
        args: [],
        outputs: { type: { response: { ok: "uint128", error: "none" } } }
      }
    },
    maps: {},
    variables: {},
    constants: {},
    non_fungible_tokens: [],
    fungible_tokens: [{ name: "fake-ft" }],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "fake-ft"
  },
  fakeNft: {
    functions: {
      mint: {
        name: "mint",
        access: "public",
        args: [{ name: "owner", type: "principal" }],
        outputs: { type: { response: { ok: "uint128", error: "none" } } }
      },
      transfer: {
        name: "transfer",
        access: "public",
        args: [
          { name: "id", type: "uint128" },
          { name: "sender", type: "principal" },
          { name: "recipient", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      getLastTokenId: {
        name: "get-last-token-id",
        access: "read_only",
        args: [],
        outputs: { type: { response: { ok: "uint128", error: "none" } } }
      },
      getOwner: {
        name: "get-owner",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { response: { ok: { optional: "principal" }, error: "none" } } }
      },
      getTokenUri: {
        name: "get-token-uri",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { response: { ok: { optional: "none" }, error: "none" } } }
      }
    },
    maps: {},
    variables: {
      lastTokenIdVar: {
        name: "last-token-id-var",
        type: "uint128",
        access: "variable"
      }
    },
    constants: {
      lastTokenIdVar: 0n
    },
    non_fungible_tokens: [{ name: "fake-nft", type: "uint128" }],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "fake-nft"
  },
  l1BridgeV1: {
    functions: {
      getPubkeyHash: {
        name: "get-pubkey-hash",
        access: "private",
        args: [{ name: "addr", type: "principal" }],
        outputs: { type: { buffer: { length: 20 } } }
      },
      handleBridgeToV1: {
        name: "handle-bridge-to-v1",
        access: "private",
        args: [
          { name: "name-id", type: "uint128" },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "inscription-id", type: { buffer: { length: 35 } } },
          { name: "signature", type: { buffer: { length: 65 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      setSignerInner: {
        name: "set-signer-inner",
        access: "private",
        args: [{ name: "signer", type: "principal" }],
        outputs: { type: "bool" }
      },
      bridgeToL1: {
        name: "bridge-to-l1",
        access: "public",
        args: [
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "inscription-id", type: { buffer: { length: 35 } } },
          { name: "signature", type: { buffer: { length: 65 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      bridgeToL2: {
        name: "bridge-to-l2",
        access: "public",
        args: [
          { name: "inscription-id", type: { buffer: { length: 35 } } },
          { name: "recipient", type: "principal" },
          { name: "signature", type: { buffer: { length: 65 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      migrateAndBridge: {
        name: "migrate-and-bridge",
        access: "public",
        args: [
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "inscription-id", type: { buffer: { length: 35 } } },
          { name: "bridge-signature", type: { buffer: { length: 65 } } },
          { name: "wrapper", type: "principal" },
          { name: "migrate-signature", type: { buffer: { length: 65 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      updateRegistryExtension: {
        name: "update-registry-extension",
        access: "public",
        args: [{ name: "new-extension", type: "principal" }],
        outputs: { type: { response: { ok: "principal", error: "uint128" } } }
      },
      updateSigner: {
        name: "update-signer",
        access: "public",
        args: [{ name: "signer", type: "principal" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      burnScriptData: {
        name: "burn-script-data",
        access: "read_only",
        args: [{ name: "recipient", type: "principal" }],
        outputs: { type: { buffer: { length: 339 } } }
      },
      generateBurnOutput: {
        name: "generate-burn-output",
        access: "read_only",
        args: [{ name: "recipient", type: "principal" }],
        outputs: { type: { buffer: { length: 34 } } }
      },
      generateBurnScript: {
        name: "generate-burn-script",
        access: "read_only",
        args: [{ name: "recipient", type: "principal" }],
        outputs: { type: { buffer: { length: 23 } } }
      },
      getSigner: {
        name: "get-signer",
        access: "read_only",
        args: [],
        outputs: { type: "principal" }
      },
      hashBurnScriptData: {
        name: "hash-burn-script-data",
        access: "read_only",
        args: [{ name: "recipient", type: "principal" }],
        outputs: { type: { buffer: { length: 20 } } }
      },
      hashForHeight: {
        name: "hash-for-height",
        access: "read_only",
        args: [{ name: "height", type: "uint128" }],
        outputs: { type: { buffer: { length: 32 } } }
      },
      hashUnwrapData: {
        name: "hash-unwrap-data",
        access: "read_only",
        args: [
          { name: "inscription-id", type: { buffer: { length: 35 } } },
          { name: "owner", type: { buffer: { length: 34 } } }
        ],
        outputs: { type: { buffer: { length: 32 } } }
      },
      hashWrapData: {
        name: "hash-wrap-data",
        access: "read_only",
        args: [
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "inscription-id", type: { buffer: { length: 35 } } }
        ],
        outputs: { type: { buffer: { length: 32 } } }
      },
      validateUnwrapSignature: {
        name: "validate-unwrap-signature",
        access: "read_only",
        args: [
          { name: "inscription-id", type: { buffer: { length: 35 } } },
          { name: "recipient", type: "principal" },
          { name: "owner", type: { buffer: { length: 34 } } },
          { name: "signature", type: { buffer: { length: 65 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      validateWrapSignature: {
        name: "validate-wrap-signature",
        access: "read_only",
        args: [
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "inscription-id", type: { buffer: { length: 35 } } },
          { name: "signature", type: { buffer: { length: 65 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      }
    },
    maps: {},
    variables: {
      ERR_INVALID_BLOCK: {
        name: "ERR_INVALID_BLOCK",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_INVALID_BURN_ADDRESS: {
        name: "ERR_INVALID_BURN_ADDRESS",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_INVALID_SIGNER: {
        name: "ERR_INVALID_SIGNER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NAME_NOT_MIGRATED: {
        name: "ERR_NAME_NOT_MIGRATED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_RECOVER: {
        name: "ERR_RECOVER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_TRANSFER: {
        name: "ERR_TRANSFER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      signerPubkeyHashVar: {
        name: "signer-pubkey-hash-var",
        type: {
          buffer: {
            length: 20
          }
        },
        access: "variable"
      },
      signerVar: {
        name: "signer-var",
        type: "principal",
        access: "variable"
      }
    },
    constants: {
      ERR_INVALID_BLOCK: {
        isOk: false,
        value: 1200n
      },
      ERR_INVALID_BURN_ADDRESS: {
        isOk: false,
        value: 1205n
      },
      ERR_INVALID_SIGNER: {
        isOk: false,
        value: 1202n
      },
      ERR_NAME_NOT_MIGRATED: {
        isOk: false,
        value: 1203n
      },
      ERR_RECOVER: {
        isOk: false,
        value: 1201n
      },
      ERR_TRANSFER: {
        isOk: false,
        value: 1204n
      },
      signerPubkeyHashVar: Uint8Array.from([
        109,
        120,
        222,
        123,
        6,
        37,
        223,
        191,
        193,
        108,
        58,
        138,
        87,
        53,
        246,
        220,
        61,
        195,
        242,
        206
      ]),
      signerVar: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM"
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch21",
    clarity_version: "Clarity2",
    contractName: "l1-bridge-v1"
  },
  l1Registry: {
    functions: {
      isExtension: {
        name: "is-extension",
        access: "private",
        args: [],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      logBridgeAction: {
        name: "log-bridge-action",
        access: "private",
        args: [
          { name: "topic", type: { "string-ascii": { length: 10 } } },
          { name: "inscription-id", type: { buffer: { length: 35 } } },
          { name: "account", type: "principal" },
          {
            name: "name-details",
            type: {
              tuple: [
                { name: "id", type: "uint128" },
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } },
                { name: "owner", type: "principal" }
              ]
            }
          }
        ],
        outputs: {
          type: {
            tuple: [
              { name: "account", type: "principal" },
              { name: "id", type: "uint128" },
              { name: "inscription-id", type: { buffer: { length: 35 } } },
              { name: "name", type: { buffer: { length: 48 } } },
              { name: "namespace", type: { buffer: { length: 20 } } },
              { name: "owner", type: "principal" },
              { name: "topic", type: { "string-ascii": { length: 10 } } }
            ]
          }
        }
      },
      unwrap: {
        name: "unwrap",
        access: "public",
        args: [
          { name: "inscription-id", type: { buffer: { length: 35 } } },
          { name: "recipient", type: "principal" }
        ],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      updateExtension: {
        name: "update-extension",
        access: "public",
        args: [{ name: "new-extension", type: "principal" }],
        outputs: { type: { response: { ok: "principal", error: "uint128" } } }
      },
      wrap: {
        name: "wrap",
        access: "public",
        args: [
          { name: "name-id", type: "uint128" },
          { name: "owner", type: "principal" },
          { name: "inscription-id", type: { buffer: { length: 35 } } }
        ],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      getExtension: {
        name: "get-extension",
        access: "read_only",
        args: [],
        outputs: { type: "principal" }
      },
      getInscriptionId: {
        name: "get-inscription-id",
        access: "read_only",
        args: [{ name: "name-id", type: "uint128" }],
        outputs: { type: { optional: { buffer: { length: 35 } } } }
      },
      getInscriptionNameProperties: {
        name: "get-inscription-name-properties",
        access: "read_only",
        args: [{ name: "inscription-id", type: { buffer: { length: 35 } } }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      getNameId: {
        name: "get-name-id",
        access: "read_only",
        args: [{ name: "inscription-id", type: { buffer: { length: 35 } } }],
        outputs: { type: { optional: "uint128" } }
      },
      getNameProperties: {
        name: "get-name-properties",
        access: "read_only",
        args: [{ name: "name-id", type: "uint128" }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      validateNameOwnedByRegistry: {
        name: "validate-name-owned-by-registry",
        access: "read_only",
        args: [{ name: "name-id", type: "uint128" }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" }
                ]
              },
              error: "uint128"
            }
          }
        }
      }
    },
    maps: {
      inscriptionsNameMap: {
        name: "inscriptions-name-map",
        key: { buffer: { length: 35 } },
        value: "uint128"
      },
      nameInscriptionsMap: {
        name: "name-inscriptions-map",
        key: "uint128",
        value: { buffer: { length: 35 } }
      }
    },
    variables: {
      ERR_DUPLICATE_INSCRIPTION: {
        name: "ERR_DUPLICATE_INSCRIPTION",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_INSCRIPTION_NOT_REGISTERED: {
        name: "ERR_INSCRIPTION_NOT_REGISTERED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_INVALID_NAME: {
        name: "ERR_INVALID_NAME",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NOT_OWNED_BY_REGISTRY: {
        name: "ERR_NOT_OWNED_BY_REGISTRY",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_TRANSFER: {
        name: "ERR_TRANSFER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_UNAUTHORIZED: {
        name: "ERR_UNAUTHORIZED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      extensionVar: {
        name: "extension-var",
        type: "principal",
        access: "variable"
      }
    },
    constants: {
      ERR_DUPLICATE_INSCRIPTION: {
        isOk: false,
        value: 1103n
      },
      ERR_INSCRIPTION_NOT_REGISTERED: {
        isOk: false,
        value: 1104n
      },
      ERR_INVALID_NAME: {
        isOk: false,
        value: 1102n
      },
      ERR_NOT_OWNED_BY_REGISTRY: {
        isOk: false,
        value: 1101n
      },
      ERR_TRANSFER: {
        isOk: false,
        value: 1100n
      },
      ERR_UNAUTHORIZED: {
        isOk: false,
        value: 4000n
      },
      extensionVar: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.l1-bridge-v1"
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch21",
    clarity_version: "Clarity2",
    contractName: "l1-registry"
  },
  nameRegistrar: {
    functions: {
      nameRegister: {
        name: "name-register",
        access: "public",
        args: [
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "amount", type: "uint128" },
          { name: "hashed-fqn", type: { buffer: { length: 20 } } },
          { name: "salt", type: { buffer: { length: 20 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      }
    },
    maps: {},
    variables: {},
    constants: {},
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "name-registrar"
  },
  nameWrapper: {
    functions: {
      registerSelf: {
        name: "register-self",
        access: "private",
        args: [],
        outputs: { type: { response: { ok: "uint128", error: "uint128" } } }
      },
      nameUpdate: {
        name: "name-update",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "zonefile-hash", type: { buffer: { length: 20 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      unwrap: {
        name: "unwrap",
        access: "public",
        args: [{ name: "recipient", type: { optional: "principal" } }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      getNameInfo: {
        name: "get-name-info",
        access: "read_only",
        args: [],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      getOwnName: {
        name: "get-own-name",
        access: "read_only",
        args: [],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      getOwner: {
        name: "get-owner",
        access: "read_only",
        args: [],
        outputs: { type: { response: { ok: "principal", error: "uint128" } } }
      },
      getWrapperId: {
        name: "get-wrapper-id",
        access: "read_only",
        args: [],
        outputs: { type: { optional: "uint128" } }
      }
    },
    maps: {},
    variables: {
      ERR_NAME_TRANSFER: {
        name: "ERR_NAME_TRANSFER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NOT_WRAPPED: {
        name: "ERR_NOT_WRAPPED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NO_NAME: {
        name: "ERR_NO_NAME",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_UNAUTHORIZED: {
        name: "ERR_UNAUTHORIZED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      wrapperIdVar: {
        name: "wrapper-id-var",
        type: {
          optional: "uint128"
        },
        access: "variable"
      }
    },
    constants: {
      ERR_NAME_TRANSFER: {
        isOk: false,
        value: 10001n
      },
      ERR_NOT_WRAPPED: {
        isOk: false,
        value: 10003n
      },
      ERR_NO_NAME: {
        isOk: false,
        value: 10000n
      },
      ERR_UNAUTHORIZED: {
        isOk: false,
        value: 10002n
      },
      wrapperIdVar: 2n
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "name-wrapper"
  },
  nameWrapperV2: {
    functions: {
      registerSelf: {
        name: "register-self",
        access: "private",
        args: [],
        outputs: { type: { response: { ok: "uint128", error: "uint128" } } }
      },
      validateOwner: {
        name: "validate-owner",
        access: "private",
        args: [],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      nameRenewal: {
        name: "name-renewal",
        access: "public",
        args: [{ name: "stx-to-burn", type: "uint128" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      nameUpdate: {
        name: "name-update",
        access: "public",
        args: [
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "zonefile-hash", type: { buffer: { length: 20 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      unwrap: {
        name: "unwrap",
        access: "public",
        args: [{ name: "recipient", type: { optional: "principal" } }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      withdrawFt: {
        name: "withdraw-ft",
        access: "public",
        args: [
          { name: "ft", type: "trait_reference" },
          { name: "amount", type: "uint128" },
          { name: "recipient", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      withdrawNft: {
        name: "withdraw-nft",
        access: "public",
        args: [
          { name: "nft", type: "trait_reference" },
          { name: "token-id", type: "uint128" },
          { name: "recipient", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      withdrawStx: {
        name: "withdraw-stx",
        access: "public",
        args: [
          { name: "amount", type: "uint128" },
          { name: "recipient", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      getNameInfo: {
        name: "get-name-info",
        access: "read_only",
        args: [],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      getOwnName: {
        name: "get-own-name",
        access: "read_only",
        args: [],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      getOwner: {
        name: "get-owner",
        access: "read_only",
        args: [],
        outputs: { type: { response: { ok: "principal", error: "uint128" } } }
      },
      getWrapperId: {
        name: "get-wrapper-id",
        access: "read_only",
        args: [],
        outputs: { type: { optional: "uint128" } }
      }
    },
    maps: {},
    variables: {
      ERR_NAME_TRANSFER: {
        name: "ERR_NAME_TRANSFER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NOT_WRAPPED: {
        name: "ERR_NOT_WRAPPED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NO_NAME: {
        name: "ERR_NO_NAME",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_UNAUTHORIZED: {
        name: "ERR_UNAUTHORIZED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      wrapperIdVar: {
        name: "wrapper-id-var",
        type: {
          optional: "uint128"
        },
        access: "variable"
      }
    },
    constants: {
      ERR_NAME_TRANSFER: {
        isOk: false,
        value: 10001n
      },
      ERR_NOT_WRAPPED: {
        isOk: false,
        value: 10003n
      },
      ERR_NO_NAME: {
        isOk: false,
        value: 10000n
      },
      ERR_UNAUTHORIZED: {
        isOk: false,
        value: 10002n
      },
      wrapperIdVar: 3n
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "name-wrapper-v2"
  },
  nftTrait: {
    functions: {},
    maps: {},
    variables: {},
    constants: {},
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "nft-trait"
  },
  proposal2: {
    functions: {
      execute: {
        name: "execute",
        access: "public",
        args: [{ name: "sender", type: "principal" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      }
    },
    maps: {},
    variables: {
      DEPLOYER: {
        name: "DEPLOYER",
        type: "principal",
        access: "constant"
      }
    },
    constants: {
      DEPLOYER: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM"
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "proposal-2"
  },
  proposalBootstrap: {
    functions: {
      addBootstrapUtils: {
        name: "add-bootstrap-utils",
        access: "private",
        args: [],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      execute: {
        name: "execute",
        access: "public",
        args: [{ name: "sender", type: "principal" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      }
    },
    maps: {},
    variables: {
      DEPLOYER: {
        name: "DEPLOYER",
        type: "principal",
        access: "constant"
      }
    },
    constants: {
      DEPLOYER: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM"
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "proposal-bootstrap"
  },
  proposalMigratorV2: {
    functions: {
      execute: {
        name: "execute",
        access: "public",
        args: [{ name: "sender", type: "principal" }],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      }
    },
    maps: {},
    variables: {
      DEPLOYER: {
        name: "DEPLOYER",
        type: "principal",
        access: "constant"
      }
    },
    constants: {
      DEPLOYER: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM"
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch21",
    clarity_version: "Clarity2",
    contractName: "proposal-migrator-v2"
  },
  proposalTrait: {
    functions: {},
    maps: {},
    variables: {},
    constants: {},
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "proposal-trait"
  },
  queryHelper: {
    functions: {
      crawlFold: {
        name: "crawl-fold",
        access: "read_only",
        args: [
          { name: "index", type: "uint128" },
          {
            name: "iterator",
            type: {
              tuple: [
                {
                  name: "names",
                  type: {
                    list: {
                      type: {
                        tuple: [
                          { name: "id", type: "uint128" },
                          {
                            name: "legacy",
                            type: {
                              optional: {
                                tuple: [
                                  { name: "lease-ending-at", type: { optional: "uint128" } },
                                  { name: "lease-started-at", type: "uint128" },
                                  { name: "owner", type: "principal" },
                                  { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                                ]
                              }
                            }
                          },
                          { name: "name", type: { buffer: { length: 48 } } },
                          { name: "namespace", type: { buffer: { length: 48 } } },
                          { name: "owner", type: "principal" }
                        ]
                      },
                      length: 20
                    }
                  }
                },
                { name: "next-id", type: { optional: "uint128" } }
              ]
            }
          }
        ],
        outputs: {
          type: {
            tuple: [
              {
                name: "names",
                type: {
                  list: {
                    type: {
                      tuple: [
                        { name: "id", type: "uint128" },
                        {
                          name: "legacy",
                          type: {
                            optional: {
                              tuple: [
                                { name: "lease-ending-at", type: { optional: "uint128" } },
                                { name: "lease-started-at", type: "uint128" },
                                { name: "owner", type: "principal" },
                                { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                              ]
                            }
                          }
                        },
                        { name: "name", type: { buffer: { length: 48 } } },
                        { name: "namespace", type: { buffer: { length: 48 } } },
                        { name: "owner", type: "principal" }
                      ]
                    },
                    length: 20
                  }
                }
              },
              { name: "next-id", type: { optional: "uint128" } }
            ]
          }
        }
      },
      crawlFromId: {
        name: "crawl-from-id",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: {
          type: {
            tuple: [
              {
                name: "names",
                type: {
                  list: {
                    type: {
                      tuple: [
                        { name: "id", type: "uint128" },
                        {
                          name: "legacy",
                          type: {
                            optional: {
                              tuple: [
                                { name: "lease-ending-at", type: { optional: "uint128" } },
                                { name: "lease-started-at", type: "uint128" },
                                { name: "owner", type: "principal" },
                                { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                              ]
                            }
                          }
                        },
                        { name: "name", type: { buffer: { length: 48 } } },
                        { name: "namespace", type: { buffer: { length: 48 } } },
                        { name: "owner", type: "principal" }
                      ]
                    },
                    length: 20
                  }
                }
              },
              { name: "next-id", type: { optional: "uint128" } }
            ]
          }
        }
      },
      crawlNames: {
        name: "crawl-names",
        access: "read_only",
        args: [{ name: "account", type: "principal" }],
        outputs: {
          type: {
            tuple: [
              {
                name: "names",
                type: {
                  list: {
                    type: {
                      tuple: [
                        { name: "id", type: "uint128" },
                        {
                          name: "legacy",
                          type: {
                            optional: {
                              tuple: [
                                { name: "lease-ending-at", type: { optional: "uint128" } },
                                { name: "lease-started-at", type: "uint128" },
                                { name: "owner", type: "principal" },
                                { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                              ]
                            }
                          }
                        },
                        { name: "name", type: { buffer: { length: 48 } } },
                        { name: "namespace", type: { buffer: { length: 48 } } },
                        { name: "owner", type: "principal" }
                      ]
                    },
                    length: 20
                  }
                }
              },
              { name: "next-id", type: { optional: "uint128" } }
            ]
          }
        }
      },
      getBnsxByName: {
        name: "get-bnsx-by-name",
        access: "read_only",
        args: [
          {
            name: "name",
            type: {
              tuple: [
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } }
              ]
            }
          }
        ],
        outputs: {
          type: {
            optional: {
              tuple: [
                { name: "id", type: "uint128" },
                {
                  name: "legacy",
                  type: {
                    optional: {
                      tuple: [
                        { name: "lease-ending-at", type: { optional: "uint128" } },
                        { name: "lease-started-at", type: "uint128" },
                        { name: "owner", type: "principal" },
                        { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                      ]
                    }
                  }
                },
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } },
                { name: "owner", type: "principal" }
              ]
            }
          }
        }
      },
      getBnsxName: {
        name: "get-bnsx-name",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: {
          type: {
            optional: {
              tuple: [
                { name: "id", type: "uint128" },
                {
                  name: "legacy",
                  type: {
                    optional: {
                      tuple: [
                        { name: "lease-ending-at", type: { optional: "uint128" } },
                        { name: "lease-started-at", type: "uint128" },
                        { name: "owner", type: "principal" },
                        { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                      ]
                    }
                  }
                },
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } },
                { name: "owner", type: "principal" }
              ]
            }
          }
        }
      },
      getLegacyName: {
        name: "get-legacy-name",
        access: "read_only",
        args: [{ name: "account", type: "principal" }],
        outputs: {
          type: {
            optional: {
              tuple: [
                { name: "lease-ending-at", type: { optional: "uint128" } },
                { name: "lease-started-at", type: "uint128" },
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } },
                { name: "owner", type: "principal" },
                { name: "zonefile-hash", type: { buffer: { length: 20 } } }
              ]
            }
          }
        }
      },
      getNames: {
        name: "get-names",
        access: "read_only",
        args: [{ name: "account", type: "principal" }],
        outputs: {
          type: {
            tuple: [
              {
                name: "legacy",
                type: {
                  optional: {
                    tuple: [
                      { name: "lease-ending-at", type: { optional: "uint128" } },
                      { name: "lease-started-at", type: "uint128" },
                      { name: "name", type: { buffer: { length: 48 } } },
                      { name: "namespace", type: { buffer: { length: 20 } } },
                      { name: "owner", type: "principal" },
                      { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                    ]
                  }
                }
              },
              {
                name: "names",
                type: {
                  list: {
                    type: {
                      tuple: [
                        { name: "id", type: "uint128" },
                        {
                          name: "legacy",
                          type: {
                            optional: {
                              tuple: [
                                { name: "lease-ending-at", type: { optional: "uint128" } },
                                { name: "lease-started-at", type: "uint128" },
                                { name: "owner", type: "principal" },
                                { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                              ]
                            }
                          }
                        },
                        { name: "name", type: { buffer: { length: 48 } } },
                        { name: "namespace", type: { buffer: { length: 48 } } },
                        { name: "owner", type: "principal" }
                      ]
                    },
                    length: 20
                  }
                }
              },
              { name: "next-id", type: { optional: "uint128" } }
            ]
          }
        }
      },
      resolveLegacyName: {
        name: "resolve-legacy-name",
        access: "read_only",
        args: [
          {
            name: "name",
            type: {
              tuple: [
                { name: "name", type: { buffer: { length: 48 } } },
                { name: "namespace", type: { buffer: { length: 20 } } }
              ]
            }
          }
        ],
        outputs: {
          type: {
            optional: {
              tuple: [
                { name: "lease-ending-at", type: { optional: "uint128" } },
                { name: "lease-started-at", type: "uint128" },
                { name: "owner", type: "principal" },
                { name: "zonefile-hash", type: { buffer: { length: 20 } } }
              ]
            }
          }
        }
      }
    },
    maps: {},
    variables: {
      ARRAY: {
        name: "ARRAY",
        type: {
          list: {
            type: "uint128",
            length: 19
          }
        },
        access: "constant"
      },
      MAX_NAMES_QUERY: {
        name: "MAX_NAMES_QUERY",
        type: "uint128",
        access: "constant"
      }
    },
    constants: {
      ARRAY: [0n, 1n, 2n, 3n, 4n, 5n, 6n, 7n, 8n, 9n, 10n, 11n, 12n, 13n, 14n, 15n, 16n, 17n, 18n],
      MAX_NAMES_QUERY: 20n
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "query-helper"
  },
  testUtils: {
    functions: {
      isDeployer: {
        name: "is-deployer",
        access: "private",
        args: [],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      nameRegister: {
        name: "name-register",
        access: "public",
        args: [
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "owner", type: "principal" }
        ],
        outputs: { type: { response: { ok: "uint128", error: "uint128" } } }
      },
      v1Register: {
        name: "v1-register",
        access: "public",
        args: [
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "namespace", type: { buffer: { length: 20 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      },
      v1RegisterTransfer: {
        name: "v1-register-transfer",
        access: "public",
        args: [
          { name: "name", type: { buffer: { length: 48 } } },
          { name: "namespace", type: { buffer: { length: 20 } } },
          { name: "recipient", type: "principal" }
        ],
        outputs: { type: { response: { ok: "bool", error: "int128" } } }
      }
    },
    maps: {},
    variables: {
      ERR_UNAUTHORIZED: {
        name: "ERR_UNAUTHORIZED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      deployer: {
        name: "deployer",
        type: "principal",
        access: "constant"
      }
    },
    constants: {
      ERR_UNAUTHORIZED: {
        isOk: false,
        value: 12000n
      },
      deployer: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM"
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "test-utils"
  },
  wrapperMigrator: {
    functions: {
      getNextWrapperId: {
        name: "get-next-wrapper-id",
        access: "private",
        args: [],
        outputs: { type: "uint128" }
      },
      resolveAndTransfer: {
        name: "resolve-and-transfer",
        access: "private",
        args: [{ name: "wrapper", type: "principal" }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "lease-ending-at", type: { optional: "uint128" } },
                  { name: "lease-started-at", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" },
                  { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      setSignersIter: {
        name: "set-signers-iter",
        access: "private",
        args: [
          {
            name: "item",
            type: {
              tuple: [
                { name: "enabled", type: "bool" },
                { name: "signer", type: { buffer: { length: 20 } } }
              ]
            }
          }
        ],
        outputs: { type: { buffer: { length: 20 } } }
      },
      isDaoOrExtension: {
        name: "is-dao-or-extension",
        access: "public",
        args: [],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      migrate: {
        name: "migrate",
        access: "public",
        args: [
          { name: "wrapper", type: "principal" },
          { name: "signature", type: { buffer: { length: 65 } } },
          { name: "recipient", type: "principal" }
        ],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "lease-ending-at", type: { optional: "uint128" } },
                  { name: "lease-started-at", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" },
                  { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      registerWrapper: {
        name: "register-wrapper",
        access: "public",
        args: [{ name: "wrapper", type: "principal" }],
        outputs: { type: { response: { ok: "uint128", error: "uint128" } } }
      },
      setSigners: {
        name: "set-signers",
        access: "public",
        args: [
          {
            name: "signers",
            type: {
              list: {
                type: {
                  tuple: [
                    { name: "enabled", type: "bool" },
                    { name: "signer", type: { buffer: { length: 20 } } }
                  ]
                },
                length: 50
              }
            }
          }
        ],
        outputs: {
          type: {
            response: {
              ok: { list: { type: { buffer: { length: 20 } }, length: 50 } },
              error: "uint128"
            }
          }
        }
      },
      debugSignature: {
        name: "debug-signature",
        access: "read_only",
        args: [
          { name: "wrapper", type: "principal" },
          { name: "signature", type: { buffer: { length: 65 } } }
        ],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "pubkey-hash", type: { buffer: { length: 20 } } },
                  { name: "valid-signer", type: "bool" }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      getIdFromWrapper: {
        name: "get-id-from-wrapper",
        access: "read_only",
        args: [{ name: "wrapper", type: "principal" }],
        outputs: { type: { optional: "uint128" } }
      },
      getLegacyName: {
        name: "get-legacy-name",
        access: "read_only",
        args: [{ name: "account", type: "principal" }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "lease-ending-at", type: { optional: "uint128" } },
                  { name: "lease-started-at", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" },
                  { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      getNameWrapper: {
        name: "get-name-wrapper",
        access: "read_only",
        args: [{ name: "name", type: "uint128" }],
        outputs: { type: { optional: "principal" } }
      },
      getWrapperFromId: {
        name: "get-wrapper-from-id",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { optional: "principal" } }
      },
      getWrapperName: {
        name: "get-wrapper-name",
        access: "read_only",
        args: [{ name: "wrapper", type: "principal" }],
        outputs: { type: { optional: "uint128" } }
      },
      hashId: {
        name: "hash-id",
        access: "read_only",
        args: [{ name: "id", type: "uint128" }],
        outputs: { type: { buffer: { length: 32 } } }
      },
      isValidSigner: {
        name: "is-valid-signer",
        access: "read_only",
        args: [{ name: "pubkey", type: { buffer: { length: 20 } } }],
        outputs: { type: "bool" }
      },
      recoverPubkeyHash: {
        name: "recover-pubkey-hash",
        access: "read_only",
        args: [
          { name: "wrapper", type: "principal" },
          { name: "signature", type: { buffer: { length: 65 } } }
        ],
        outputs: { type: { response: { ok: { buffer: { length: 20 } }, error: "uint128" } } }
      },
      verifyWrapper: {
        name: "verify-wrapper",
        access: "read_only",
        args: [
          { name: "wrapper", type: "principal" },
          { name: "signature", type: { buffer: { length: 65 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      }
    },
    maps: {
      idWrapperMap: { name: "id-wrapper-map", key: "uint128", value: "principal" },
      migratorSignersMap: {
        name: "migrator-signers-map",
        key: { buffer: { length: 20 } },
        value: "bool"
      },
      nameWrapperMap: {
        name: "name-wrapper-map",
        key: "uint128",
        value: "principal"
      },
      wrapperIdMap: { name: "wrapper-id-map", key: "principal", value: "uint128" },
      wrapperNameMap: {
        name: "wrapper-name-map",
        key: "principal",
        value: "uint128"
      }
    },
    variables: {
      ERR_INVALID_CONTRACT_NAME: {
        name: "ERR_INVALID_CONTRACT_NAME",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NAME_TRANSFER: {
        name: "ERR_NAME_TRANSFER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NO_NAME: {
        name: "ERR_NO_NAME",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_RECOVER: {
        name: "ERR_RECOVER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_UNAUTHORIZED: {
        name: "ERR_UNAUTHORIZED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_WRAPPER_ALREADY_REGISTERED: {
        name: "ERR_WRAPPER_ALREADY_REGISTERED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_WRAPPER_NOT_REGISTERED: {
        name: "ERR_WRAPPER_NOT_REGISTERED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_WRAPPER_USED: {
        name: "ERR_WRAPPER_USED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ROLE: {
        name: "ROLE",
        type: {
          "string-ascii": {
            length: 10
          }
        },
        access: "constant"
      },
      nextWrapperIdVar: {
        name: "next-wrapper-id-var",
        type: "uint128",
        access: "variable"
      }
    },
    constants: {
      ERR_INVALID_CONTRACT_NAME: {
        isOk: false,
        value: 6003n
      },
      ERR_NAME_TRANSFER: {
        isOk: false,
        value: 6004n
      },
      ERR_NO_NAME: {
        isOk: false,
        value: 6000n
      },
      ERR_RECOVER: {
        isOk: false,
        value: 6002n
      },
      ERR_UNAUTHORIZED: {
        isOk: false,
        value: 6001n
      },
      ERR_WRAPPER_ALREADY_REGISTERED: {
        isOk: false,
        value: 6007n
      },
      ERR_WRAPPER_NOT_REGISTERED: {
        isOk: false,
        value: 6006n
      },
      ERR_WRAPPER_USED: {
        isOk: false,
        value: 6005n
      },
      ROLE: "mig-signer",
      nextWrapperIdVar: 0n
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch20",
    clarity_version: "Clarity1",
    contractName: "wrapper-migrator"
  },
  wrapperMigratorV2: {
    functions: {
      resolveAndTransfer: {
        name: "resolve-and-transfer",
        access: "private",
        args: [{ name: "wrapper", type: "principal" }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "lease-ending-at", type: { optional: "uint128" } },
                  { name: "lease-started-at", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" },
                  { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      setSignersIter: {
        name: "set-signers-iter",
        access: "private",
        args: [
          {
            name: "item",
            type: {
              tuple: [
                { name: "enabled", type: "bool" },
                { name: "signer", type: "principal" }
              ]
            }
          }
        ],
        outputs: { type: { buffer: { length: 20 } } }
      },
      isDaoOrExtension: {
        name: "is-dao-or-extension",
        access: "public",
        args: [],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      },
      migrate: {
        name: "migrate",
        access: "public",
        args: [
          { name: "wrapper", type: "principal" },
          { name: "signature", type: { buffer: { length: 65 } } },
          { name: "recipient", type: "principal" }
        ],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "id", type: "uint128" },
                  { name: "lease-ending-at", type: { optional: "uint128" } },
                  { name: "lease-started-at", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" },
                  { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      setSigners: {
        name: "set-signers",
        access: "public",
        args: [
          {
            name: "signers",
            type: {
              list: {
                type: {
                  tuple: [
                    { name: "enabled", type: "bool" },
                    { name: "signer", type: "principal" }
                  ]
                },
                length: 50
              }
            }
          }
        ],
        outputs: {
          type: {
            response: {
              ok: { list: { type: { buffer: { length: 20 } }, length: 50 } },
              error: "uint128"
            }
          }
        }
      },
      construct: {
        name: "construct",
        access: "read_only",
        args: [{ name: "hash-bytes", type: { buffer: { length: 20 } } }],
        outputs: {
          type: {
            response: {
              ok: "principal",
              error: {
                tuple: [
                  { name: "error_code", type: "uint128" },
                  { name: "value", type: { optional: "principal" } }
                ]
              }
            }
          }
        }
      },
      getLegacyName: {
        name: "get-legacy-name",
        access: "read_only",
        args: [{ name: "account", type: "principal" }],
        outputs: {
          type: {
            response: {
              ok: {
                tuple: [
                  { name: "lease-ending-at", type: { optional: "uint128" } },
                  { name: "lease-started-at", type: "uint128" },
                  { name: "name", type: { buffer: { length: 48 } } },
                  { name: "namespace", type: { buffer: { length: 20 } } },
                  { name: "owner", type: "principal" },
                  { name: "zonefile-hash", type: { buffer: { length: 20 } } }
                ]
              },
              error: "uint128"
            }
          }
        }
      },
      getNameWrapper: {
        name: "get-name-wrapper",
        access: "read_only",
        args: [{ name: "name", type: "uint128" }],
        outputs: { type: { optional: "principal" } }
      },
      getWrapperName: {
        name: "get-wrapper-name",
        access: "read_only",
        args: [{ name: "wrapper", type: "principal" }],
        outputs: { type: { optional: "uint128" } }
      },
      hashMigrationData: {
        name: "hash-migration-data",
        access: "read_only",
        args: [
          { name: "wrapper", type: "principal" },
          { name: "sender", type: "principal" }
        ],
        outputs: { type: { buffer: { length: 32 } } }
      },
      hashPrincipal: {
        name: "hash-principal",
        access: "read_only",
        args: [{ name: "wrapper", type: "principal" }],
        outputs: { type: { buffer: { length: 32 } } }
      },
      isValidSigner: {
        name: "is-valid-signer",
        access: "read_only",
        args: [{ name: "signer", type: "principal" }],
        outputs: { type: "bool" }
      },
      verifyWrapper: {
        name: "verify-wrapper",
        access: "read_only",
        args: [
          { name: "wrapper", type: "principal" },
          { name: "sender", type: "principal" },
          { name: "signature", type: { buffer: { length: 65 } } }
        ],
        outputs: { type: { response: { ok: "bool", error: "uint128" } } }
      }
    },
    maps: {
      migratorSignersMap: {
        name: "migrator-signers-map",
        key: { buffer: { length: 20 } },
        value: "bool"
      },
      nameWrapperMap: {
        name: "name-wrapper-map",
        key: "uint128",
        value: "principal"
      },
      wrapperNameMap: {
        name: "wrapper-name-map",
        key: "principal",
        value: "uint128"
      }
    },
    variables: {
      ERR_INVALID_CONTRACT_NAME: {
        name: "ERR_INVALID_CONTRACT_NAME",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NAME_TRANSFER: {
        name: "ERR_NAME_TRANSFER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_NO_NAME: {
        name: "ERR_NO_NAME",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_RECOVER: {
        name: "ERR_RECOVER",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_UNAUTHORIZED: {
        name: "ERR_UNAUTHORIZED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ERR_WRAPPER_USED: {
        name: "ERR_WRAPPER_USED",
        type: {
          response: {
            ok: "none",
            error: "uint128"
          }
        },
        access: "constant"
      },
      ROLE: {
        name: "ROLE",
        type: {
          "string-ascii": {
            length: 10
          }
        },
        access: "constant"
      },
      networkAddrVersion: {
        name: "network-addr-version",
        type: {
          buffer: {
            length: 1
          }
        },
        access: "constant"
      },
      wrappedIdVar: {
        name: "wrapped-id-var",
        type: "uint128",
        access: "variable"
      },
      wrapperDeployer: {
        name: "wrapper-deployer",
        type: {
          buffer: {
            length: 20
          }
        },
        access: "variable"
      }
    },
    constants: {
      ERR_INVALID_CONTRACT_NAME: {
        isOk: false,
        value: 6003n
      },
      ERR_NAME_TRANSFER: {
        isOk: false,
        value: 6004n
      },
      ERR_NO_NAME: {
        isOk: false,
        value: 6000n
      },
      ERR_RECOVER: {
        isOk: false,
        value: 6002n
      },
      ERR_UNAUTHORIZED: {
        isOk: false,
        value: 6001n
      },
      ERR_WRAPPER_USED: {
        isOk: false,
        value: 6005n
      },
      ROLE: "mig-signer",
      networkAddrVersion: Uint8Array.from([26]),
      wrappedIdVar: 0n,
      wrapperDeployer: Uint8Array.from([
        109,
        120,
        222,
        123,
        6,
        37,
        223,
        191,
        193,
        108,
        58,
        138,
        87,
        53,
        246,
        220,
        61,
        195,
        242,
        206
      ])
    },
    non_fungible_tokens: [],
    fungible_tokens: [],
    epoch: "Epoch21",
    clarity_version: "Clarity2",
    contractName: "wrapper-migrator-v2"
  }
};
var deployments = {
  bnsV1: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.bns-v1",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.bns-v1",
    testnet: null,
    mainnet: null
  },
  bnsxExtensions: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.bnsx-extensions",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.bnsx-extensions",
    testnet: "STQSAQN4XGY5SE0GGXF9QXZYWWG0Q8A6SDX206PG.bnsx-extensions",
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.bnsx-extensions"
  },
  bnsxRegistry: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.bnsx-registry",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.bnsx-registry",
    testnet: "STQSAQN4XGY5SE0GGXF9QXZYWWG0Q8A6SDX206PG.bnsx-registry",
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.bnsx-registry"
  },
  communityHandlesV2: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.community-handles-v2",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.community-handles-v2",
    testnet: null,
    mainnet: null
  },
  extensionTrait: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.extension-trait",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.extension-trait",
    testnet: "STQSAQN4XGY5SE0GGXF9QXZYWWG0Q8A6SDX206PG.extension-trait",
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.extension-trait"
  },
  fakeFt: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.fake-ft",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.fake-ft",
    testnet: null,
    mainnet: null
  },
  fakeNft: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.fake-nft",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.fake-nft",
    testnet: null,
    mainnet: null
  },
  l1BridgeV1: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.l1-bridge-v1",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.l1-bridge-v1",
    testnet: null,
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.l1-bridge-v1"
  },
  l1Registry: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.l1-registry",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.l1-registry",
    testnet: null,
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.l1-registry"
  },
  nameRegistrar: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.name-registrar",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.name-registrar",
    testnet: "STQSAQN4XGY5SE0GGXF9QXZYWWG0Q8A6SDX206PG.name-registrar",
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.name-registrar"
  },
  nameWrapper: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.name-wrapper",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.name-wrapper",
    testnet: null,
    mainnet: null
  },
  nameWrapperV2: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.name-wrapper-v2",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.name-wrapper-v2",
    testnet: null,
    mainnet: null
  },
  nftTrait: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.nft-trait",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.nft-trait",
    testnet: "STQSAQN4XGY5SE0GGXF9QXZYWWG0Q8A6SDX206PG.nft-trait",
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.nft-trait"
  },
  proposal2: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.proposal-2",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.proposal-2",
    testnet: null,
    mainnet: null
  },
  proposalBootstrap: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.proposal-bootstrap",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.proposal-bootstrap",
    testnet: "STQSAQN4XGY5SE0GGXF9QXZYWWG0Q8A6SDX206PG.proposal-bootstrap",
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.proposal-bootstrap"
  },
  proposalMigratorV2: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.proposal-migrator-v2",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.proposal-migrator-v2",
    testnet: null,
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.proposal-migrator-v2"
  },
  proposalTrait: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.proposal-trait",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.proposal-trait",
    testnet: "STQSAQN4XGY5SE0GGXF9QXZYWWG0Q8A6SDX206PG.proposal-trait",
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.proposal-trait"
  },
  queryHelper: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.query-helper",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.query-helper",
    testnet: "STQSAQN4XGY5SE0GGXF9QXZYWWG0Q8A6SDX206PG.query-helper",
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.query-helper"
  },
  testUtils: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.test-utils",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.test-utils",
    testnet: "STQSAQN4XGY5SE0GGXF9QXZYWWG0Q8A6SDX206PG.test-utils",
    mainnet: null
  },
  wrapperMigrator: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.wrapper-migrator",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.wrapper-migrator",
    testnet: "STQSAQN4XGY5SE0GGXF9QXZYWWG0Q8A6SDX206PG.wrapper-migrator",
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.wrapper-migrator"
  },
  wrapperMigratorV2: {
    devnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.wrapper-migrator-v2",
    simnet: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.wrapper-migrator-v2",
    testnet: null,
    mainnet: "SP1JTCR202ECC6333N7ZXD7MK7E3ZTEEE1MJ73C60.wrapper-migrator-v2"
  }
};
var project = {
  contracts,
  deployments
};

// src/utils.ts
import { bytesToAscii, hexToBytes, bytesToHex, asciiToBytes } from "micro-stacks/common";
import { toUnicode } from "@bns-x/punycode";
import { getRandomBytes } from "micro-stacks/crypto";
import { createHash } from "crypto";
function getContractParts(identifier) {
  const [addr, name] = identifier.split(".");
  if (!addr || !name) {
    throw new Error(`Invalid contract ID: ${identifier}`);
  }
  return [addr, name];
}
function asciiToHex(str) {
  return bytesToHex(asciiToBytes(str));
}
function hexToAscii(str) {
  return bytesToAscii(hexToBytes(str));
}
function bytesToName(input) {
  if (typeof input === "string") {
    return bytesToAscii(hexToBytes(input));
  }
  return bytesToAscii(input);
}
function convertNameBuff(nameObj) {
  const { name: nameB, namespace: ns, ...rest } = nameObj;
  const name = bytesToName(nameB);
  const namespace = bytesToName(ns);
  const combined = `${name}.${namespace}`;
  return {
    name,
    namespace,
    combined,
    decoded: toUnicode(combined),
    ...rest
  };
}
function convertLegacyDetailsJson(details) {
  if (details === null)
    return null;
  return {
    ...details,
    leaseEndingAt: details.leaseEndingAt === null ? null : Number(details.leaseEndingAt),
    leaseStartedAt: Number(details.leaseStartedAt)
  };
}
function getNameParts(fqn) {
  const parts = fqn.split(".");
  if (parts.length === 3) {
    return parts.slice(1);
  }
  if (parts.length !== 2) {
    throw new Error(`Invalid FQN. Received: ${fqn}`);
  }
  return parts;
}
function parseFqn(fqn) {
  const parts = fqn.split(".");
  if (parts.length === 3) {
    const [subdomain, name, namespace] = parts;
    return {
      subdomain,
      name,
      namespace
    };
  }
  if (parts.length === 2) {
    const [name, namespace] = parts;
    return {
      name,
      namespace
    };
  }
  throw new Error(`Invalid name: ${fqn}`);
}
function randomSalt() {
  return getRandomBytes(20);
}
function hash160(data) {
  const sha256 = createHash("sha256").update(data).digest();
  const ripe160 = createHash("ripemd160").update(sha256).digest();
  return new Uint8Array(ripe160);
}
function hexToString(hex) {
  let str = "";
  for (let i = 0; i < hex.length; i += 2) {
    const charCode = parseInt(hex.substr(i, 2), 16);
    str += String.fromCharCode(charCode);
  }
  return str;
}
function hashFqn(name, namespace, salt) {
  const saltHex = typeof salt === "string" ? salt : hexToString(bytesToHex(salt));
  const nameBytes = asciiToHex(name);
  const namespaceBytes = asciiToHex(namespace);
  const fqnWithSalt = `${nameBytes}2e${namespaceBytes}${saltHex}`;
  const fqnWithSaltString = hexToString(fqnWithSalt);
  return hash160(fqnWithSaltString);
}
var NO_EXPIRATION_NAMESPACES = /* @__PURE__ */ new Set([
  "stx",
  "app",
  "stacks",
  "podcast",
  "miner",
  "mining",
  "helloworld",
  "stacking",
  "blockstack"
]);
function doesNamespaceExpire(namespace) {
  return !NO_EXPIRATION_NAMESPACES.has(namespace);
}
var validAsciiCharsStr = "abcdefghijklmnopqrstuvwxyz0123456789-_";
var validAsciiChars = new Set(validAsciiCharsStr.split(""));
function isNameValid(name) {
  const validChars = name.split("").every((c) => validAsciiChars.has(c));
  const validLength = name.length >= 1 && name.length <= 37;
  return validChars && validLength;
}

// src/types.ts
import { z } from "zod";
var zonefileRecordsSchema = z.intersection(
  z.record(z.string(), z.string()),
  z.object({
    btcAddress: z.optional(z.string()).describe("Returns the `_btc._addr` TXT record from the user's zonefile, if present."),
    nostr: z.optional(z.string()).describe("Returns the `_._nostr` TXT record from the user's zonefile, if present")
  })
).describe("Some records are parsed and returned from the user's zonefile for convenience");
var nameDetailsBaseSchema = z.object({
  address: z.string().describe("The STX address of the owner of this name"),
  blockchain: z.string().describe('The blockchain where this name is owned. Currently only "stacks" is supported'),
  expire_block: z.optional(z.number()).describe("The Bitcoin block when this name expires"),
  grace_period: z.optional(z.number()),
  last_txid: z.string().describe("The last indexed transaction ID where an update to this name occurred"),
  resolver: z.optional(z.string()),
  status: z.string(),
  zonefile: z.string().describe("The user's full zonefile"),
  zonefile_hash: z.string().describe("The sha256 hash of the user's zonefile"),
  decoded: z.string().describe(
    "Returns a UTF-8-encoded version of the name. If the name is punycode, this will return the Unicode version of that name."
  ),
  inscriptionId: z.optional(z.string()),
  inscription: z.optional(
    z.object({
      blockHeight: z.number(),
      timestamp: z.string(),
      txid: z.string(),
      sat: z.string()
    })
  ),
  zonefileRecords: zonefileRecordsSchema
});
var nameDetailsCoreSchema = nameDetailsBaseSchema.extend({
  isBnsx: z.literal(false)
});
var nameDetailsBnsxSchema = nameDetailsBaseSchema.extend({
  isBnsx: z.literal(true),
  id: z.number(),
  wrapper: z.string()
});
var nameDetailsSchema = z.union([nameDetailsCoreSchema, nameDetailsBnsxSchema]);
var legacyPropsSchema = z.object({
  zonefileHash: z.string(),
  leaseEndingAt: z.nullable(z.number()),
  leaseStartedAt: z.optional(z.number()),
  owner: z.string(),
  combined: z.string(),
  decoded: z.string(),
  name: z.string(),
  namespace: z.string()
});
var bnsxNameSchema = z.object({
  id: z.number().int(),
  combined: z.string(),
  decoded: z.string(),
  name: z.string(),
  namespace: z.string()
});
var displayNameResponseSchema = z.object({ name: z.nullable(z.string()) });
var simpleNamesForAddressSchema = z.object({
  names: z.array(z.string())
});
var namesByAddressBaseSchema = z.object({
  names: z.array(z.string()).describe("A list of names that the address owns"),
  displayName: z.nullable(z.string()).describe('A single name that can be shown as the "display name" for the user'),
  coreName: z.nullable(legacyPropsSchema).describe("The address's BNS Core name")
});
var namesByAddressBnsxSchema = namesByAddressBaseSchema.extend({
  primaryName: z.nullable(z.string()).describe("The address's BNSx primary name"),
  primaryProperties: z.nullable(bnsxNameSchema).describe("The name properties of the address's BNSx name"),
  nameProperties: z.array(bnsxNameSchema).describe("An array of BNSx name properties")
});
var simpleOrExtraNamesByAddress = z.union([
  simpleNamesForAddressSchema,
  namesByAddressBnsxSchema
]);
var nameStringsForAddressSchema = z.object({
  coreName: z.nullable(z.string()).describe("The BNS core name owned by this address"),
  bnsxNames: z.array(
    z.object({
      name: z.string(),
      id: z.number()
    })
  ).describe("The BNSx names owned by this address")
});

// src/namespaces.ts
var ALL_NAMESPACES = {
  btc: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 262800n,
    namespaceImport: "SP24TC3Y58XKHZ7GX0N69X50BFYD9ECSR8PGAE3H6",
    priceFunction: {
      base: 1000n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 200n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 0n
  },
  stx: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "SP24TC3Y58XKHZ7GX0N69X50BFYD9ECSR8PGAE3H6",
    priceFunction: {
      base: 4n,
      buckets: [6n, 5n, 4n, 3n, 2n, 1n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n],
      coeff: 10833n,
      noVowelDiscount: 4n,
      nonalphaDiscount: 4n
    },
    revealedAt: 0n
  },
  app: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "SP24TC3Y58XKHZ7GX0N69X50BFYD9ECSR8PGAE3H6",
    priceFunction: {
      base: 4n,
      buckets: [6n, 5n, 4n, 3n, 2n, 1n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n],
      coeff: 10833n,
      noVowelDiscount: 4n,
      nonalphaDiscount: 4n
    },
    revealedAt: 0n
  },
  mega: {
    canUpdatePriceFunction: true,
    launchedAt: 71814n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 71814n
  },
  id: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 52595n,
    namespaceImport: "SP364R1Y0XYC8PYXEK0PE3V752RAJB2GNWF28WP90",
    priceFunction: {
      base: 4n,
      buckets: [6n, 5n, 4n, 3n, 2n, 1n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n],
      coeff: 10833n,
      noVowelDiscount: 4n,
      nonalphaDiscount: 4n
    },
    revealedAt: 0n
  },
  helloworld: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "SP3TJ4WRTXW0YFWF1TWKWCG7E6Z6MZDTWAVB6SW5V",
    priceFunction: {
      base: 2n,
      buckets: [15n, 14n, 13n, 12n, 11n, 10n, 9n, 8n, 7n, 6n, 5n, 4n, 3n, 2n, 1n, 0n],
      coeff: 173n,
      noVowelDiscount: 5n,
      nonalphaDiscount: 2n
    },
    revealedAt: 0n
  },
  crashpunk: {
    canUpdatePriceFunction: true,
    launchedAt: 71814n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 71814n
  },
  trajan: {
    canUpdatePriceFunction: true,
    launchedAt: 74966n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 74966n
  },
  frens: {
    canUpdatePriceFunction: true,
    launchedAt: 70971n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v1",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 70971n
  },
  stacking: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "SP24TC3Y58XKHZ7GX0N69X50BFYD9ECSR8PGAE3H6",
    priceFunction: {
      base: 4n,
      buckets: [6n, 5n, 4n, 3n, 2n, 1n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n],
      coeff: 10833n,
      noVowelDiscount: 4n,
      nonalphaDiscount: 4n
    },
    revealedAt: 0n
  },
  megapont: {
    canUpdatePriceFunction: true,
    launchedAt: 70971n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v1",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 70971n
  },
  bitcoinmonkey: {
    canUpdatePriceFunction: true,
    launchedAt: 71806n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 71806n
  },
  citycoins: {
    canUpdatePriceFunction: true,
    launchedAt: 71810n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 71810n
  },
  blockstack: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "SP355AMV5R2A5C7VQCF4YPPAS05W93HTB68574W7S",
    priceFunction: {
      base: 4n,
      buckets: [7n, 6n, 5n, 4n, 3n, 2n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 10833n,
      noVowelDiscount: 4n,
      nonalphaDiscount: 4n
    },
    revealedAt: 0n
  },
  zest: {
    canUpdatePriceFunction: true,
    launchedAt: 82088n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 82088n
  },
  miner: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "SP24TC3Y58XKHZ7GX0N69X50BFYD9ECSR8PGAE3H6",
    priceFunction: {
      base: 1n,
      buckets: [0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n],
      coeff: 0n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 0n
  },
  podcast: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "SPHYHD2ZJ11HWRDKVZ160QX7FD7PCVNT2N9CT2H6",
    priceFunction: {
      base: 40n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 10833n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 0n
  },
  mining: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "SP24TC3Y58XKHZ7GX0N69X50BFYD9ECSR8PGAE3H6",
    priceFunction: {
      base: 4n,
      buckets: [6n, 5n, 4n, 3n, 2n, 1n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n],
      coeff: 10833n,
      noVowelDiscount: 4n,
      nonalphaDiscount: 4n
    },
    revealedAt: 0n
  },
  stacks: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "SP24TC3Y58XKHZ7GX0N69X50BFYD9ECSR8PGAE3H6",
    priceFunction: {
      base: 4n,
      buckets: [6n, 5n, 4n, 3n, 2n, 1n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n],
      coeff: 10833n,
      noVowelDiscount: 4n,
      nonalphaDiscount: 4n
    },
    revealedAt: 0n
  },
  fren: {
    canUpdatePriceFunction: true,
    launchedAt: 71814n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 71814n
  },
  satoshibles: {
    canUpdatePriceFunction: true,
    launchedAt: 70971n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v1",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 70971n
  },
  sats: {
    canUpdatePriceFunction: true,
    launchedAt: 98025n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 98025n
  },
  stacksparrots: {
    canUpdatePriceFunction: true,
    launchedAt: 70971n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v1",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 70971n
  },
  satoshible: {
    canUpdatePriceFunction: true,
    launchedAt: 71810n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 71810n
  },
  graphite: {
    canUpdatePriceFunction: true,
    launchedAt: 0n,
    lifetime: 52595n,
    namespaceImport: "SP3X2W6GTGVVG9RW78F1MT7M9AQFS2HWV2JETXPG2",
    priceFunction: {
      base: 4n,
      buckets: [6n, 5n, 4n, 3n, 2n, 1n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n],
      coeff: 10833n,
      noVowelDiscount: 4n,
      nonalphaDiscount: 4n
    },
    revealedAt: 0n
  },
  spaghettipunk: {
    canUpdatePriceFunction: true,
    launchedAt: 75870n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 75870n
  },
  stacksparrot: {
    canUpdatePriceFunction: true,
    launchedAt: 71814n,
    lifetime: 52560n,
    namespaceImport: "SPC0KWNBJ61BDZRPF3W2GHGK3G3GKS8WZ7ND33PS.community-handles-v2",
    priceFunction: {
      base: 999999999999999999999999999999n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 1n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 71814n
  },
  satoshi: {
    canUpdatePriceFunction: false,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM",
    priceFunction: {
      base: 10n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 1n],
      coeff: 1000n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 0n
  },
  testable: {
    canUpdatePriceFunction: false,
    launchedAt: 0n,
    lifetime: 0n,
    namespaceImport: "ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM",
    priceFunction: {
      base: 10n,
      buckets: [1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n, 1n],
      coeff: 64000n,
      noVowelDiscount: 1n,
      nonalphaDiscount: 1n
    },
    revealedAt: 0n
  }
};

// src/namespace-helpers.ts
var NAMESPACES = Object.keys(ALL_NAMESPACES);
function hasVowels(name) {
  return /[aeiou]/i.test(name.toLowerCase());
}
function hasNonAlpha(name) {
  return /[\d\-_]/i.test(name);
}
function computeNamePrice(name, namespace) {
  const props = ALL_NAMESPACES[namespace];
  if (typeof props === void 0) {
    throw new Error("Unable to compute name price - invalid namespace");
  }
  const exponentIndex = Math.min(15, name.length - 1);
  const pf = props.priceFunction;
  const exponent = pf.buckets[exponentIndex];
  const vowelDiscount = !hasVowels(name) ? pf.noVowelDiscount : 1n;
  const nonAlphaDiscount = hasNonAlpha(name) ? pf.nonalphaDiscount : 1n;
  const discount = vowelDiscount > nonAlphaDiscount ? vowelDiscount : nonAlphaDiscount;
  return pf.base ** exponent * pf.coeff / discount * 10n;
}
export {
  ALL_NAMESPACES,
  NAMESPACES,
  NO_EXPIRATION_NAMESPACES,
  asciiToHex,
  bnsxNameSchema,
  bytesToName,
  computeNamePrice,
  contracts,
  convertLegacyDetailsJson,
  convertNameBuff,
  deployments,
  displayNameResponseSchema,
  doesNamespaceExpire,
  getContractParts,
  getNameParts,
  hasNonAlpha,
  hasVowels,
  hash160,
  hashFqn,
  hexToAscii,
  hexToString,
  isNameValid,
  legacyPropsSchema,
  nameDetailsBaseSchema,
  nameDetailsBnsxSchema,
  nameDetailsCoreSchema,
  nameDetailsSchema,
  nameStringsForAddressSchema,
  namesByAddressBaseSchema,
  namesByAddressBnsxSchema,
  parseFqn,
  project,
  randomSalt,
  simpleNamesForAddressSchema,
  simpleOrExtraNamesByAddress,
  zonefileRecordsSchema
};
